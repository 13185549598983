





















import { defineComponent } from '@vue/composition-api';
import { mapGetters, mapState } from 'vuex';

export default defineComponent<any, Record<string, unknown>, any, any, any>({
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState({
      activeClusterName: (state: any) => state.EditHeatingSystemRegistersModule.activeClusterName,
    }),
    ...mapGetters(['filteredClusters']),
  },
  methods: {
    setActiveCluster(name: string) {
      this.$store.commit('setActiveCluster', name);
    },
  },
});
