






































































































import { defineComponent, PropType } from '@vue/composition-api';
import { ChartSet, ChartStyle, LineChartType } from '@/components/charts/model';
import { isBooleanMetric, formatMetricValue, formatMetricName } from '@/features/core/util/metric-formatters';
import { ChartOptions } from '@/components/charts/echarts/model';
import { INPUT_DATE_PICKER_MODE_META, Option } from '@/features/ui/inputs/model';
import moment, { Moment } from 'moment';
import {
  MetricDescriptorInput,
  MetricResolutionAggregator,
  MetricResolutionInput,
  MetricResolutionTimeSource,
  SpotRole,
} from '@/types/iot-portal';
import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { CoreSpotMetricsPanelControlSpotQuery } from '@/features/core/components/spot-metrics-panel-control/__generated__/CoreSpotMetricsPanelControlSpotQuery';
import aggregationQuery from '@/features/core/components/spot-metrics-graph-panel-control/aggregation.gql';
import spotQuery from '@/features/core/components/spot-metrics-graph-panel-control/spot.gql';
import {
  CoreSpotMetricsGraphPanelControlAggregationQuery,
  CoreSpotMetricsGraphPanelControlAggregationQuery_aggregation_first_metrics,
} from '@/features/core/components/spot-metrics-graph-panel-control/__generated__/CoreSpotMetricsGraphPanelControlAggregationQuery';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import { CoreSpotMetricsGraphPanelControlSpotQuery_spot_first_metrics } from '@/features/core/components/spot-metrics-graph-panel-control/__generated__/CoreSpotMetricsGraphPanelControlSpotQuery';
import { ContinuousMetricPoint, DiscreteMetricPoint, normalizeMetricPoint } from '@/features/core/util/metrics';
import { DateTime } from 'luxon';
import { groupBy, partition, intersection, union } from 'lodash';
import { isDef } from '@/util/lang';
import { DeviceRole, DeviceRoleAggregationMetric } from '@/features/core/model';
import { checkMetricName } from '@/features/core/util/metric-calculations';
import { removeLabelFromName } from '@/util/heating-systems';
import { HeatingSystem } from '@/util/modbus';

type MetricPointUnion = ContinuousMetricPoint | DiscreteMetricPoint;

const getAggregatorLabel: (aggregator: MetricResolutionAggregator) => string = (aggregator) => {
  switch (aggregator) {
    case MetricResolutionAggregator.MIN:
      return 'Minimum';
    case MetricResolutionAggregator.MAX:
      return 'Maximum';
    case MetricResolutionAggregator.MEAN:
      return 'Durchschnitt';
    case MetricResolutionAggregator.SUM:
      return 'Summe';
    default:
      return '';
  }
};

export default defineComponent({
  mixins: [DeviceRoleMapMixin],
  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },
    heatingSystem: {
      type: Object as PropType<HeatingSystem>,
      required: true,
    },
    modbusMetricNames: {
      type: Array as PropType<string[]>,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      isLoadingSpot: false,
      isLoadingMetrics: false,
      chartStyle: ChartStyle.LINE,
      chartType: LineChartType.ECHARTS,
      startDate: null as Date | null,
      stopDate: null as Date | null,
      spotId: null as string | null,
      spot: null as CoreSpotMetricsPanelControlSpotQuery['spot'] | null,
      aggregation: null as CoreSpotMetricsGraphPanelControlAggregationQuery['aggregation'] | null,
      chartOptions: {
        legend: { bottom: 60, padding: [0, 0, 0, 0] },
        grid: {
          bottom: 150,
        },
      } as ChartOptions,
      mergedMetrics: [] as
        | CoreSpotMetricsGraphPanelControlSpotQuery_spot_first_metrics[]
        | CoreSpotMetricsGraphPanelControlAggregationQuery_aggregation_first_metrics[],
      aggregator: undefined as MetricResolutionAggregator | undefined,
      aggregationInterval: 'PT1H',
      aggregationIntervalOptions: [
        { label: 'Monatlich', value: 'P1M' },
        { label: 'Wöchentlich', value: 'P1W' },
        { label: 'Täglich', value: 'P1D' },
        { label: 'Stündlich', value: 'PT1H' },
      ] as Option[],
    };
  },
  computed: {
    show(): boolean {
      return this.$store.getters.showAdvancedViewGraph;
    },
    isLoading(): boolean {
      return this.isLoadingSpot || this.isLoadingMetrics;
    },
    yAxisDimensions(): Array<string | number> {
      if (this.chartStyle === ChartStyle.STEP) {
        return ['0', '1'];
      }
      return [];
    },
    inputCellBasis(): number {
      return window.innerWidth < 1920 ? 0.5 : 0.25;
    },
    metricNames(): string[] {
      return this.spot?.first.role === SpotRole.HEATING_CONTROL
        ? this.modbusMetricNames
        : ['temperature1', 'temperature2', 'outsideTemperature'];
    },
    datePickerStartAttrs(): JSONObject {
      const attrs: JSONObject = {};

      const mode =
        this.aggregationInterval === 'P1M' ? INPUT_DATE_PICKER_MODE_META.MONTH : INPUT_DATE_PICKER_MODE_META.DAY;

      attrs.disabledDates = {
        from:
          this.stopDate && this.aggregationInterval !== 'P1W'
            ? moment(this.stopDate).subtract(1, mode.duration).toDate()
            : this.now().toDate(),
        to: undefined,
      };

      // enable only month view
      if (this.aggregationInterval === 'P1M') {
        attrs.minimumView = mode.duration;
        attrs.maximumView = mode.duration;
      }

      return attrs;
    },
    datePickerStopAttrs(): JSONObject {
      const attrs: JSONObject = {};
      const mode =
        this.aggregationInterval === 'P1M' ? INPUT_DATE_PICKER_MODE_META.MONTH : INPUT_DATE_PICKER_MODE_META.DAY;

      const datePickerStartDate = this.startDate ? moment(this.startDate).toDate() : undefined;

      attrs.disabledDates = {
        from: this.now().add(1, mode.duration).toDate(),
        to:
          this.aggregationInterval === 'P1M'
            ? moment(datePickerStartDate).add(1, mode.duration).toDate()
            : datePickerStartDate,
      };

      // enable only month view
      if (mode !== INPUT_DATE_PICKER_MODE_META.DAY) {
        attrs.minimumView = mode.duration;
        attrs.maximumView = mode.duration;
      }

      return attrs;
    },
    metricData(): ChartSet[] {
      const aggregatedMetrics = this.aggregation?.first.metrics ?? [];

      const combinedMetrics = [...aggregatedMetrics];

      if (combinedMetrics.length === 0) {
        return [];
      }

      if (this.mergedMetrics.length === 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.mergedMetrics = combinedMetrics;
      }

      if (this.mergedMetrics.length === combinedMetrics.length) {
        this.mergedMetrics.map((metric) => {
          const combinedMetricIndex = combinedMetrics.findIndex(({ name }) => name === metric.name);
          metric.points = combinedMetrics[combinedMetricIndex].points;
        });
      }

      if (this.mergedMetrics.length > combinedMetrics.length) {
        const extraMetrics = this.mergedMetrics.filter(
          (metric) => !combinedMetrics.some(({ name }) => name === metric.name),
        );
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.mergedMetrics = this.mergedMetrics.filter((metric) => !extraMetrics.includes(metric));
      }

      if (this.mergedMetrics.length < combinedMetrics.length) {
        const missingMetrics = combinedMetrics.filter(
          (metric) => !this.mergedMetrics.some(({ name }) => name === metric.name),
        );
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.mergedMetrics = [...this.mergedMetrics, ...missingMetrics];
      }

      return this.mergedMetrics.map((metric) => ({
        label:
          this.spot?.first.metricConfigurations.find(({ name }) => name === metric.name)?.label ??
          formatMetricName(metric.name),
        name: metric.name,
        color: metric.name === 'outsideTemperature' ? '#3b3939' : undefined,
        points:
          metric.__typename === 'ContinuousMetric'
            ? this.getGdprMetricPoints(metric.points)
                .map(normalizeMetricPoint)
                .map(({ time, value }) => ({ x: time, y: value }))
            : this.getGdprMetricPoints(metric.points)
                .map(normalizeMetricPoint)
                .map(({ time, value }) => ({ x: time, y: value })),
      }));
    },
    descriptorDates(): Pick<MetricDescriptorInput, 'start' | 'stop'> {
      const timezoneOffset = moment(this.startDate).utcOffset();

      const start =
        this.startDate ?? moment(this.startDate).utc().add(timezoneOffset, 'minutes').startOf('day').toDate();
      const stop = this.stopDate ?? moment(this.stopDate).add(1, 'day').startOf('day').toDate();

      return { start, stop };
    },
    possibleMetricAggregators(): MetricResolutionAggregator[] {
      return intersection<MetricResolutionAggregator>(
        union<MetricResolutionAggregator>(
          ...Array.from(this.aggregationMetricMap.values()).map(({ aggregators }) => aggregators),
        ),
        [
          MetricResolutionAggregator.MIN,
          MetricResolutionAggregator.MAX,
          MetricResolutionAggregator.MEAN,
          MetricResolutionAggregator.SUM,
        ],
      );
    },
    metricAggregatorOptions(): Option<MetricResolutionAggregator>[] {
      return this.possibleMetricAggregators.map((aggregator) => ({
        value: aggregator,
        label: getAggregatorLabel(aggregator),
      }));
    },
    aggregationMetricMap(): Map<string, DeviceRoleAggregationMetric> {
      const deviceRoleMap = this.deviceRoleMap as Record<string, DeviceRole>;

      if (this.spot?.first.role === SpotRole.HEATING_CONTROL) {
        return new Map(
          this.modbusMetricNames?.map((metric) => [
            metric,
            {
              name: metric,
              aggregators: [MetricResolutionAggregator.MEAN],
              timeSource: MetricResolutionTimeSource.START,
            },
          ]),
        );
      }
      return new Map(
        deviceRoleMap[this.spot?.first.role ?? '']?.aggregationMetricNames?.map((metric) => [metric.name, metric]),
      );
    },
    aggregationResolutionMap(): Map<string, MetricResolutionInput | undefined> {
      return new Map(
        Array.from(this.aggregationMetricMap.entries())
          .filter((entry) => (this.aggregator ? entry[1].aggregators.includes(this.aggregator) : true))
          .map(([aggregationMetricName, metric]: [string, DeviceRoleAggregationMetric]) => {
            if (metric === undefined) {
              return [aggregationMetricName, undefined];
            }

            return [
              aggregationMetricName,
              {
                intervalLength: this.aggregationInterval ?? 'PT1M',
                timeSource: metric.timeSource,
                aggregator: this.aggregator,
              },
            ];
          }),
      );
    },
    getHeatingSystemComponents(): any {
      const heatingSystemComponents = new Map<string, string>(
        this.heatingSystem.heatingSystemMeasurementGroups.flatMap(({ heatingSystemMeasurements }) => {
          return heatingSystemMeasurements.map((measurement) => {
            return [measurement.spotId, removeLabelFromName(measurement.measurementName, measurement.metricLabel)];
          });
        }),
      );

      return Array.from(heatingSystemComponents);
    },
  },
  watch: {
    spotId(val) {
      this.fetchData(val);
    },
  },
  beforeMount() {
    this.startDate = moment().subtract(30, 'days').startOf('day').toDate();
    this.stopDate = moment().toDate();

    if (this.getHeatingSystemComponents.length > 0) {
      // show the first component data on mounted
      this.spotId = this.getHeatingSystemComponents[0][0];
    }
  },
  async mounted() {
    if (this.spotId) {
      await this.fetchData(this.spotId);
    }
  },
  methods: {
    closeModal() {
      this.$store.commit('showAdvancedViewGraph', false);
    },
    async fetchData(spotId: string) {
      await this.fetchSpot(spotId);
      await this.fetchMetrics(spotId);
    },
    yAxisLabelFormatter(value: string | number): string {
      if (this.chartStyle === ChartStyle.STEP && this.metricNames.some(isBooleanMetric)) {
        return value === '0' ? 'no/off' : 'yes/on';
      }
      return this.metricNames.length === 0 ? `${value}` : `${this.formatValue(value, this.metricNames[0]) ?? value}`;
    },
    formatValue(value: string | number, name: string): string {
      return formatMetricValue(this.metricNames.find((v) => v === name) ?? '', value);
    },
    onUpdateStartDate(date: Date): void {
      if (this.aggregationInterval === 'P1W') {
        date = moment(date).startOf('isoWeek').toDate();
        this.onUpdateStopDate(moment(date).endOf('isoWeek').toDate());
      }
      this.startDate = date;
      this.fetchData(String(this.spotId));
    },
    onUpdateStopDate(date: Date | undefined): void {
      let updatedDate = moment().toDate();
      if (date) {
        updatedDate = moment(date)
          .endOf(this.aggregationInterval === 'P1M' ? 'month' : 'day')
          .toDate();
      }

      this.stopDate = updatedDate;
      this.fetchData(String(this.spotId));
    },
    now(): Moment {
      return moment();
    },
    updateAggregator(aggregator: MetricResolutionAggregator): void {
      this.aggregator = aggregator;
      this.fetchData(String(this.spotId));
    },
    updateAggregationInterval(interval: Duration): void {
      this.onUpdateStopDate(undefined);
      this.aggregationInterval = interval;
      this.fetchData(String(this.spotId));
    },
    getGdprMetricPoints(metricPoints: any[]): any[] {
      if (
        ![SpotRole.HEAT_COST_ALLOCATOR, SpotRole.HEAT_METER_COUNTER, SpotRole.WATER_METER_HOT].includes(
          this.spot?.first.role ?? SpotRole.UNKNOWN,
        )
      ) {
        return metricPoints;
      }
      const now = DateTime.utc();

      const [newerThan2Weeks, olderThan2Weeks] = partition(
        metricPoints,
        ({ time }) => DateTime.fromISO(time).plus({ weeks: 2 }) >= now,
      );

      const pointsGroupedByYearMonth = groupBy(
        olderThan2Weeks,
        ({ time }) => `${DateTime.fromISO(time).year}-${DateTime.fromISO(time).month}`,
      );

      const filteredPointsOlderThan2Weeks = Object.values(pointsGroupedByYearMonth).flatMap((points) =>
        this.filter1thAnd16th(points),
      );

      const gdprPoints = [...newerThan2Weeks, ...filteredPointsOlderThan2Weeks].sort(
        (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime(),
      );

      return gdprPoints;
    },
    filter1thAnd16th(metricPoints: MetricPointUnion[]): MetricPointUnion[] {
      const firstOfMonth = metricPoints.find(({ time }) => {
        return DateTime.fromISO(time).hasSame(DateTime.fromISO(time).startOf('month'), 'day');
      });

      const sixteenthOfMonth = metricPoints.find(({ time }) => {
        const date = DateTime.fromISO(time);

        return DateTime.fromISO(time).hasSame(DateTime.utc(date.year, date.month, 16), 'day');
      });

      if (firstOfMonth === undefined && sixteenthOfMonth === undefined) {
        return [];
      }

      return [firstOfMonth, sixteenthOfMonth].filter(isDef);
    },
    async fetchSpot(spotId: string) {
      try {
        this.isLoadingSpot = true;
        const { data } = await this.$apollo.query<CoreSpotMetricsPanelControlSpotQuery>({
          query: spotQuery,
          fetchPolicy: 'no-cache',
          variables: {
            spotId,
          },
        });
        this.spot = data.spot;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.isLoadingSpot = false;
      }
    },
    async fetchMetrics(spotId: string) {
      try {
        this.isLoadingMetrics = true;
        const { data } = await this.$apollo.query<CoreSpotMetricsGraphPanelControlAggregationQuery>({
          query: aggregationQuery,
          client: APOLLO_CLIENT.PORTAL_CLIENT,
          variables: {
            descriptors: Array.from(this.aggregationResolutionMap.entries())
              .filter(([name]) => this.metricNames.map((metricName) => checkMetricName(metricName)).includes(name))
              .map(([name, resolution]) => ({
                name: checkMetricName(name),
                resolution,
                take: 100_000,
                ...this.descriptorDates,
              })),
            spotId,
            includeOutsideTemperature: true,
          },
          fetchPolicy: 'no-cache',
        });
        this.aggregation = data.aggregation;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.isLoadingMetrics = false;
      }
    },
    onClickComponent(spotId: string) {
      this.spotId = spotId;
    },
  },
});
