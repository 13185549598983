










































































import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import { HeatingSystemSummary } from '@/features/domain-ui/heating-system-list-table/model';
import {
  getHeatingSystemsComponentsWithTypes,
  MetadataStatus,
  isComponentMetadataCompleted,
} from '@/util/heating-systems';
import {
  HeatingSystem,
  HeatingSystemComponent,
  HeatingSystemMetadata,
  HeatingSystemMetadataKey,
  HeatingSystemMetadataResponse,
} from '@/features/core/store/page-modules/heating-systems-metadata/heating-systems-metadata.module';
import HeatingSystemsMetadataForm from './HeatingSystemsMetadataForm.vue';
import HeatingSystemsMetadataApplySettings from './HeatingSystemsMetadataApplySettings.vue';
import HeatingSystemsMetadataAudit from './HeatingSystemsMetadataAudit.vue';
import { isString } from 'lodash';
import Spinner from '@/components/clickables/Spinner.vue';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import GroupsMixin from '@/features/core/components/mixins/groups';
import { HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items } from '@/hsc-api/queries/__generated__/HeatingSystemCollectionWithPaginationQuery';
import heatingSystemsListQuery from '@/hsc-api/queries/HeatingSystemCollectionWithPaginationQuery.gql';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import { HeatingSystemHealth } from '@/types/iot-portal';
import {
  AlertHeatingSystemSummaries_statuses,
  AlertHeatingSystemSummariesVariables,
} from '@/hsc-api/queries/__generated__/AlertHeatingSystemSummaries';
import alertHeatingSystemSummariesQuery from '@/hsc-api/queries/AlertTicketsSummaryByHeatingSystemIdQuery.gql';

type HeatingSystemList = HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items;

export default defineComponent({
  components: {
    HeatingSystemsMetadataForm,
    HeatingSystemsMetadataApplySettings,
    HeatingSystemsMetadataAudit,
    Spinner,
  },
  mixins: [GroupsMixin, PaginationQueryStringMixin],
  props: {
    treeNodeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoadingMetadata: false,
      summariesLoading: false,
      metadata: {} as HeatingSystemMetadataKey,
      filters: {
        searchInput: '',
        metadataStatus: null,
      },
      metadataStatusOptions: [
        {
          label: 'Pflichtangaben vollständig',
          value: MetadataStatus.MANDATORY_INFORMATION_COMPLETE,
        },
        {
          label: 'Pflichtangaben unvollständig',
          value: MetadataStatus.MANDATORY_INFORMATION_INCOMPLETE,
        },
      ],
      previousActiveComponent: null as HeatingSystemComponent | null | undefined,
      heatingSystemsData: [] as HeatingSystemSummary[],
      nonMandatoryComponentTypes: ['BUFFER', 'HEATING_CIRCUIT', 'TELE_HEATING'],
    };
  },
  computed: {
    ...mapGetters(['openModal', 'openApplySettings', 'openAuditView']),
    heatingSystems(): HeatingSystem[] {
      return this.$store.getters.heatingSystems;
    },
    heatingSystemsMetadata(): HeatingSystemMetadataResponse[] | null {
      return this.$store.getters.heatingSystemsMetadata;
    },
    heatingSystemsComponents(): HeatingSystemComponent[] {
      return this.$store.getters.heatingSystemsComponents;
    },
    activeHeatingSystem(): HeatingSystem | undefined {
      return this.$store.getters.activeHeatingSystem;
    },
    activeHeatingSystemComponent(): HeatingSystemComponent | undefined {
      return this.$store.getters.activeHeatingSystemComponent;
    },
    filteredHeatingSystems(): HeatingSystem[] {
      let heatingSystems: HeatingSystem[] = [];
      if (this.filters.searchInput) {
        const result = this.heatingSystems.filter((heatingSystem: HeatingSystem) =>
          heatingSystem.street?.toLocaleLowerCase().includes(this.filters.searchInput.toLocaleLowerCase()),
        );
        heatingSystems = result.length > 0 ? result : this.heatingSystems;
      } else if (this.filters.metadataStatus) {
        heatingSystems = this.filterHeatingSystemsByStatus();
      } else {
        heatingSystems = this.heatingSystems;
      }

      return heatingSystems.sort((a, b) => (a.street ?? '').localeCompare(b.street ?? ''));
    },
    filteredComponents(): HeatingSystemComponent[] {
      let components = this.heatingSystemsComponents.filter((component) =>
        this.allowedComponentTypes().includes(component.type),
      );
      if (this.filters.searchInput) {
        const result = this.heatingSystemsComponents.filter((component: HeatingSystemComponent) =>
          component.label.toLocaleLowerCase().includes(this.filters.searchInput.toLocaleLowerCase()),
        );
        components = result;
      } else if (this.filters.metadataStatus) {
        components = this.filterComponentsByStatus();
      }

      components.forEach((item) => {
        if (item.type === this.previousActiveComponent?.type) {
          item.active = true;
        }
      });

      return components.sort((a, b) => a.label.localeCompare(b.label));
    },
    formData(): HeatingSystemMetadata {
      return this.$store.getters.formData;
    },
    spotIdsWithCompletedMetadata(): string[] {
      const spotIds = this.getSpotIdsWithCompletedMetadata();
      const completedMetadata = this.heatingSystemsMetadata?.filter((hsMetadata) =>
        isComponentMetadataCompleted(hsMetadata),
      );
      const result = [...new Set(completedMetadata?.map((hs) => hs.spotId))];

      return [...result, ...spotIds];
    },
  },
  watch: {
    heatingSystemsData(value) {
      const heatingSystems = value.map((item: HeatingSystemSummary) => {
        return {
          ...item,
          active: false,
        };
      });

      this.$store.commit('setHeatingSystems', heatingSystems);

      this.checkParams();
    },
    'filters.metadataStatus'() {
      this.resetComponents();
    },
  },
  async mounted() {
    await this.fetchHeatingSystemsList();
    await this.fetchHeatingSystemsMetadata();
  },
  methods: {
    getSpotIdsWithCompletedMetadata(): string[] {
      const uniqueSpotIds = new Set<string>();

      this.heatingSystems.forEach((hs) => {
        hs.components.heatingSystemMeasurementGroups?.forEach((group) => {
          // non-mandatory component types should be considered completed since no metadata validation is required.
          if (this.nonMandatoryComponentTypes.includes(group.systemComponentTypeName)) {
            group.heatingSystemMeasurements?.forEach((measurement) => {
              uniqueSpotIds.add(measurement.spotId);
            });
          }
        });
      });

      return Array.from(uniqueSpotIds);
    },
    closeModal() {
      this.$store.commit('openModal', false);

      // clear selected values
      this.resetComponents();
      this.resetHeatingSystems();
    },
    toggleAuditView() {
      if (!this.activeHeatingSystemComponent) {
        this.$store.dispatch('ADD_TOAST_MESSAGES', {
          messages: [{ text: 'Please select a component', class: 'info' }],
        });
      } else {
        this.$store.commit('toggleAuditView');
      }
    },
    async onHeatingSystemChange(id: string) {
      const activeHeatingSystem = this.heatingSystems.find((item) => item.components.id === id);

      // save previously selected component
      this.previousActiveComponent = this.activeHeatingSystemComponent;

      // set active heating system
      this.heatingSystems.forEach((heatingSystem) => {
        heatingSystem.active = heatingSystem.components.id === id ? true : false;
      });

      // close "apply settings" section
      this.$store.commit('openApplySettings', false);

      // get active heating system components
      if (activeHeatingSystem) {
        const components = getHeatingSystemsComponentsWithTypes(activeHeatingSystem);
        const componentOptions = Array.from(components, ([key, value]) => ({
          value: key,
          label: value.component,
          groupName: value.groupName,
          active: false,
          type: value.type,
        }));
        this.$store.commit('setHeatingSystemsComponents', componentOptions);
      }

      // fetch updated HS metadata
      await this.fetchHeatingSystemsMetadata();

      this.setActiveComponentMetadata();
    },
    filterHeatingSystemsByStatus(): HeatingSystem[] {
      const heatingSystems = {
        complete: [] as HeatingSystem[],
        incomplete: [] as HeatingSystem[],
      };

      this.heatingSystems.forEach((hs) => {
        const spotIds = Array.from(
          new Set([
            ...hs.components.heatingSystemMeasurementGroups.flatMap((g) =>
              g.heatingSystemMeasurements.flatMap((m) => m.spotId),
            ),
          ]),
        );

        if (spotIds.some((spotId: string) => this.spotIdsWithCompletedMetadata.includes(spotId))) {
          heatingSystems.complete.push(hs);
        } else {
          heatingSystems.incomplete.push(hs);
        }
      });

      if (this.filters.metadataStatus === 'MANDATORY_INFORMATION_COMPLETE') {
        return heatingSystems.complete;
      } else if (this.filters.metadataStatus === 'MANDATORY_INFORMATION_INCOMPLETE') {
        return heatingSystems.incomplete;
      }

      return this.heatingSystems;
    },
    filterComponentsByStatus(): HeatingSystemComponent[] {
      // exclude non-mandatory components from filtered list
      const components = this.heatingSystemsComponents.filter(
        (item) =>
          !this.nonMandatoryComponentTypes.includes(item.type) && this.allowedComponentTypes().includes(item.type),
      );
      if (this.filters.metadataStatus === 'MANDATORY_INFORMATION_COMPLETE') {
        return components.filter((item) => this.spotIdsWithCompletedMetadata.includes(item.value));
      }

      return components.filter((item) => !this.spotIdsWithCompletedMetadata.includes(item.value));
    },
    async onHeatingSystemComponentChange(id: string) {
      this.heatingSystemsComponents.forEach((component) => {
        component.active = component.value === id ? true : false;
      });
      // close "apply settings" section
      this.$store.commit('openApplySettings', false);

      this.setActiveComponentMetadata();
    },
    setActiveComponentMetadata(): void {
      const spotId = this.activeHeatingSystemComponent?.value;
      const metadataObj = this.heatingSystemsMetadata?.find((metadata) => metadata.spotId === spotId);
      if (metadataObj) {
        this.metadata = metadataObj.metadata as HeatingSystemMetadataKey;
      } else {
        this.metadata = {} as HeatingSystemMetadataKey;
      }
    },
    async fetchHeatingSystemsMetadata(): Promise<HeatingSystemMetadataResponse[] | null> {
      try {
        this.isLoadingMetadata = true;
        const res = await this.$store.dispatch('getAllHeatingSystemMetadata');
        if (!res.ok) {
          return null;
        }

        const metadata = await res.json();
        this.$store.commit('setHeatingSystemMetadata', metadata);

        return metadata;
      } catch (error) {
        return null;
      } finally {
        this.isLoadingMetadata = false;
      }
    },
    // check if there was a heating system passed via params and select it.
    checkParams(): void {
      const heatingSystemId = this.$route.query['hsid'];
      if (heatingSystemId && isString(heatingSystemId)) {
        this.onHeatingSystemChange(heatingSystemId);
      }
    },
    resetHeatingSystems(): void {
      this.heatingSystems.forEach((hs) => {
        hs.active = false;
      });
    },
    resetComponents(): void {
      this.heatingSystemsComponents.forEach((hs) => {
        hs.active = false;
      });
    },
    async onFormSaved() {
      await this.fetchHeatingSystemsMetadata();
      this.setActiveComponentMetadata();
    },
    async fetchAlertSummariesByHeatingSystem(
      heatingSystems: HeatingSystemList[],
    ): Promise<AlertHeatingSystemSummaries_statuses[] | []> {
      try {
        this.summariesLoading = true;
        const heatingSystemIds = heatingSystems.map((hs) => hs.id);

        const variables: AlertHeatingSystemSummariesVariables = { hscIds: heatingSystemIds };
        const { data: alertTicketsData } = await this.$apollo.query({
          query: alertHeatingSystemSummariesQuery,
          client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
          variables,
          fetchPolicy: 'no-cache',
        });

        return alertTicketsData?.statuses || [];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return [];
      } finally {
        this.summariesLoading = false;
      }
    },
    async heatingSystemSummary(heatingSystems: HeatingSystemList[]): Promise<HeatingSystemSummary[]> {
      const alertSummarries = await this.fetchAlertSummariesByHeatingSystem(heatingSystems);
      const summaries: HeatingSystemSummary[] = [];
      for (const item of heatingSystems) {
        const status = alertSummarries.find((alert: AlertHeatingSystemSummaries_statuses) => alert.id == item.id);
        const summary: HeatingSystemSummary = {
          name: item.name,
          health: status?.health ?? HeatingSystemHealth.OK,
          statusText: status?.statusText ?? '',
          statuses: status?.statuses ?? [],
          street: item.street,
          heatingType: item.heatingType,
          boilerDevice: item.boilerDevice,
          boilerModel: item.boilerModel,
          productSerial: item.productSerial,
          manufacturer: item.manufacturer,
          boilerManufacturer: item.boilerManufacturer,
          energyOutputRange: item.energyOutputRange,
          waterStorageType: item.waterStorageType,
          waterStorageModel: item.waterStorageModel,
          waterStorageManufacturer: item.waterStorageManufacturer,
          waterStorageCapacity: item.waterStorageCapacity,
          maintenanceCompany: item.maintenanceCompany,
          numApartmentUnits: item.numCommercialUnits,
          numCommercialUnits: item.numCommercialUnits,
          suppliedQuantity: item.suppliedQuantity,
          lastHydraulicBalancing: item.lastHydraulicBalancing,
          consumptions: item.heatingSystemConsumptions,
          components: item,
          siteId: item.siteId,
        };

        summaries.push(summary);
      }

      return summaries;
    },
    async fetchHeatingSystemsList(): Promise<void> {
      try {
        const { data } = await this.$apollo.query({
          query: heatingSystemsListQuery,
          client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
          fetchPolicy: 'no-cache',
          variables: {
            customerIdOrSiteId: this.treeNodeId,
            skip: this.skip,
            take: 500,
          },
        });

        // filter out items with empty heatingSystemMeasurementGroups
        const filteredHeatingSystems = data.heatingSystemsList?.items?.filter(
          (hs: HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items) =>
            hs.heatingSystemMeasurementGroups.length > 0,
        );

        this.heatingSystemsData = await this.heatingSystemSummary(filteredHeatingSystems);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    allowedComponentTypes(): string[] {
      return ['BOILER', 'BUFFER', 'HEATING_CIRCUIT', 'TELE_HEATING', 'WARM_HEATING'];
    },
  },
});
