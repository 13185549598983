var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-modal',{attrs:{"id":"advanced-view-graph-modal","visible":_vm.show,"no-close-on-backdrop":""}},[_c('ui-panel',{staticClass:"header"},[_c('h2',[_vm._v("Anlagenüberblick")]),_c('p',{staticClass:"object-name"},[_vm._v("Anlage: "+_vm._s(_vm.name))]),_c('ui-clickable',{staticClass:"close-button",attrs:{"tag":"button"},on:{"click":function($event){return _vm.closeModal()}}},[_c('img',{attrs:{"src":require("@/features/ui/assets/icons/close.svg"),"alt":"Schließen"}})])],1),_c('ui-panel',{staticClass:"wrapper"},[_c('section',{staticClass:"content"},[_c('ui-flex',{staticClass:"sub-header"},[_c('ui-cell',[_vm._v("Daten & Zeiträume")])],1),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":_vm.inputCellBasis,"min":250,"max":280}},[_c('ui-input-date',_vm._b({attrs:{"value":_vm.startDate,"monday-first":true,"format":function (date) { return (date.isSame(new Date(), 'day') ? 'Aktuell' : date.format('L')); },"placeholder":"Von"},on:{"update":function($event){return _vm.onUpdateStartDate($event)}}},'ui-input-date',_vm.datePickerStartAttrs,false))],1),_c('ui-cell',{attrs:{"basis":_vm.inputCellBasis,"min":250,"max":280}},[_c('ui-input-date',_vm._b({attrs:{"value":_vm.stopDate,"monday-first":true,"format":function (date) { return (date.isSame(new Date(), 'day') ? 'Aktuell' : date.format('L')); },"placeholder":"Aktuell"},on:{"update":function($event){return _vm.onUpdateStopDate($event)}}},'ui-input-date',_vm.datePickerStopAttrs,false))],1),_c('ui-cell',{attrs:{"basis":_vm.inputCellBasis,"min":250,"max":280}},[_c('ui-input-select',{attrs:{"value":_vm.aggregator,"options":_vm.metricAggregatorOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"placeholder":"Aggregationsfunktion"},on:{"update":function($event){return _vm.updateAggregator($event)}}})],1),_c('ui-cell',{attrs:{"basis":_vm.inputCellBasis,"min":250,"max":280}},[_c('ui-input-select',{ref:"aggregationIntervalRef",attrs:{"value":_vm.aggregationInterval,"options":_vm.aggregationIntervalOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"placeholder":"Aggregationsintervall"},on:{"update":function($event){return _vm.updateAggregationInterval($event)}}})],1)],1),(!_vm.isLoading && _vm.metricData.length === 0)?_c('ui-cell',{attrs:{"grow":1}},[_c('ui-flex',{attrs:{"stretch":"","items":"center"}},[_c('ui-cell',{},[_vm._v("Für dieses Gerät sind keine Daten vorhanden")])],1)],1):_vm._e(),(_vm.isLoading)?_c('ui-table-skeleton',{attrs:{"column-count":3,"header-line-count":1}}):_vm._e(),(!_vm.isLoading && _vm.metricData && _vm.metricData.length > 0)?_c('ui-flex',[_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-chart-style-switcher',{staticClass:"chart-style-switcher",model:{value:(_vm.chartStyle),callback:function ($$v) {_vm.chartStyle=$$v},expression:"chartStyle"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-dynamic-chart',{key:_vm.chartStyle,staticClass:"chart",attrs:{"sets":_vm.metricData,"format-value":_vm.formatValue,"chart-style":_vm.chartStyle,"type":_vm.chartType,"smooth":true,"chart-options":_vm.chartOptions,"aggregation-interval":"minute"}})],1)],1):_vm._e(),_c('ui-flex',[_c('ui-cell',[_c('div',{staticClass:"components"},_vm._l((_vm.getHeatingSystemComponents),function(ref){
var id = ref[0];
var metricName = ref[1];
return _c('span',{key:id,class:id === _vm.spotId ? 'active' : '',on:{"click":function($event){return _vm.onClickComponent(id)}}},[_vm._v(" "+_vm._s(metricName)+" ")])}),0)])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }