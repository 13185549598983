

























import { defineComponent } from '@vue/composition-api';
import { mapState, mapActions, mapGetters } from 'vuex';

export default defineComponent<any, Record<string, unknown>, any, any, any>({
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState({
      activeHeatingSystemId: (state: any) => state.EditHeatingSystemRegistersModule.activeHeatingSystemId,
    }),
    ...mapGetters(['filteredHeatingSystems']),
  },
  methods: {
    setActiveHeatingSystem(id: number) {
      this.$store.commit('setActiveHeatingSystem', id);
    },
    getListItemSubText(heatingSystem: any): string {
      return 'offen';
    },
  },
});
