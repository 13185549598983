






















































































import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import { ORIGIN_ENDPOINT_DECODERS } from '@/env';
import { keycloak } from '@/keycloak';

interface Register {
  registerNumber: string;
  registerName: string;
  metricName: string;
  tableData: string;
}

interface Cluster {
  name: string;
  deviceModel: string;
  registerName: string;
}

interface Register {
  tableData: string;
}

interface Registers {
  registers: Register[];
}

export default defineComponent({
  data() {
    return {
      selectedTab: '', // Default selected tab
      modelNames: [] as string[], // Initialize the modelName array
      registerData: {} as Registers, // Initialize the registerData object with registers array
      filterRegisterData: {} as Registers,
      clusters: [] as Cluster[], // Initialize the clusters array
    };
  },
  computed: {
    ...mapGetters(['showEditMetricsModal']),
    filteredRegisters(): Register[] {
      return this.filterRegisterData.registers?.filter(
        (register: Register) => register.tableData === 'table' || register.tableData === 'edit',
      );
    },
  },
  watch: {
    showEditMetricsModal(val) {
      if (val === true) {
        void this.modbusDecoderTable(); // Call the function when the modal is shown
      }
    },
  },
  mounted() {
    localStorage.removeItem('clusters'); // Ensure local storage is blank by default
  },
  methods: {
    allowDrop(event: any) {
      event.preventDefault();
    },
    drag(event: any) {
      event.dataTransfer.setData('drop', event.target.id);
    },
    drop(event: any) {
      event.preventDefault();
      const data = event.dataTransfer.getData('drop');
      const draggedElement = document.getElementById(data);
      const dropzoneType = event.target.getAttribute('data-type');
      if (draggedElement && draggedElement.querySelector('td:last-child')?.textContent === dropzoneType) {
        event.target.appendChild(draggedElement);
      }
    },

    closeModal() {
      this.$store.commit('showEditMetricsModal', false);
    },

    serchFilter({ target }: { target: HTMLInputElement }) {
      const searchString = target.value.trim().toLowerCase();
      if (searchString === '' || searchString === undefined) {
        this.filterRegisterData.registers = this.registerData.registers;
      } else {
        this.filterRegisterData.registers = this.registerData.registers.filter((register) =>
          (register.registerNumber + register.tableData + register.registerName + register.metricName)
            .toLowerCase()
            .includes(searchString),
        );
      }
    },

    async modbusDecoderTable(): Promise<void> {
      const response = await fetch(`${ORIGIN_ENDPOINT_DECODERS}/modbus/api/decoders`, {
        method: 'GET',
        headers: {
          authorization: `Bearer ${keycloak?.token ?? ''}`,
        },
      });
      if (!response.ok) {
        return;
      }
      const data = await response.json();
      this.registerData.registers = data.flatMap((device: { registers: any }) => device.registers); // Flatten and save the fetched data in registerData
      this.registerData.registers = this.registerData.registers.filter(
        (register) => register.tableData === 'table' || register.tableData === 'edit',
      ); // Filter the registers
      this.modelNames = data
        .map((device: { deviceModel: string }, index: number) => device.deviceModel)
        .filter((model: any) => model !== ''); // Save the deviceModel values in modelName, filter out empty values
      this.selectedTab = this.modelNames[0] ?? 'Tab1'; // Set the default selected tab to the first modelName
      this.filterRegisterData.registers = this.registerData.registers;
    },

    handlePlusClick() {
      // Function to handle plus icon click
      const editRegisters = this.filterRegisterData.registers.filter((register) => register.tableData === 'edit');
      if (editRegisters.length > 0) {
        const newCluster: Cluster = {
          name: `Cluster ${this.clusters.length + 1}`,
          deviceModel: this.selectedTab,
          registerName:
            this.filterRegisterData.registers.find((register) => register.tableData === 'edit')?.registerName || 'N/A',
        };
        this.clusters.push(newCluster);
        localStorage.setItem('clusters', JSON.stringify(this.clusters));
      }
    },
  },
});
