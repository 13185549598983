export function getStartOfDayTimestamp(date: Date | null): Date | null {
  if (!date) {
    return null;
  }

  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0);
  return startOfDay;
}

export function getEndOfDayTimestamp(date: Date | null): Date | null {
  if (!date) {
    return null;
  }

  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59);
  return endOfDay;
}

export function isDateRangeInvalid(fromDate: Date | null, untilDate: Date | null): boolean {
  // From date can't be after until date
  return !!fromDate && !!untilDate && untilDate.getTime() < fromDate.getTime();
}
