





















import { defineComponent } from '@vue/composition-api';
import { mapState, mapActions, mapGetters } from 'vuex';

export default defineComponent<any, Record<string, unknown>, any, any, any>({
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState({
      activeControlModelName: (state: any) => state.EditHeatingSystemRegistersModule.activeControlModelName,
    }),
    ...mapGetters(['filteredControlModels']),
  },
  methods: {
    setActiveControlModel(name: string) {
      this.$store.commit('setActiveControlModel', name);
    },
  },
});
