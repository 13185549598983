
























































































































































































































































































































































































































































































































































































































































































import {
  BufferTypes,
  HeatingCircuitRadiatorTypes,
  HeatingCircuitTemperatureControlHardware,
  HeatingCircuitTypes,
  HeatingSystem,
  HeatingSystemComponent,
  HeatingSystemMetadata,
  HeatingSystemMetadataKey,
  HeatingSystemMetadataResponse,
  TeleHeatingType,
  TemperatureControlParameter,
  WaterHeatingTypes,
} from '@/features/core/store/page-modules/heating-systems-metadata/heating-systems-metadata.module';
import { Option } from '@/features/ui/inputs/model';
import { defineComponent, PropType } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import Spinner from '@/components/clickables/Spinner.vue';

type ErrorMessage = {
  field: string;
  message: string;
};

export default defineComponent({
  components: {
    Spinner,
  },
  props: {
    meta: {
      type: Object as PropType<HeatingSystemMetadataKey>,
      required: true,
    },
  },
  emits: ['form-saved'],
  data() {
    return {
      options: [
        {
          label: 'ja',
          value: true,
        },
        {
          label: 'nein',
          value: false,
        },
      ] as Option[],
      waterHeatingOptions: [
        {
          label: 'Frischwasserstation',
          value: WaterHeatingTypes.FRESH_WATER_STATION,
        },
        {
          label: 'Speicherladesystem',
          value: WaterHeatingTypes.BUFFER_CHARGING_SYSTEM,
        },
        {
          label: 'Heizwendelspeicher',
          value: WaterHeatingTypes.BUFFER_HEATING_COIL_SYSTEM,
        },
      ],
      bufferTypeOptions: [
        { label: 'Solar', value: BufferTypes.SOLAR },
        { label: 'Wärmespeicher', value: BufferTypes.HEAT_ENERGY_STORAGE },
        { label: 'Trinkwasserbereitung', value: BufferTypes.POTABLE_WATER },
      ],
      heatingCircuitHardwareOptions: [
        { label: 'Drosselschaltung', value: HeatingCircuitTemperatureControlHardware.FLOW_CTRL_VALVE },
        { label: 'Differenzdruckgeregelt', value: HeatingCircuitTemperatureControlHardware.DIFF_PRESSURE_CTRL },
        { label: 'Umlenkschaltung', value: HeatingCircuitTemperatureControlHardware.FLOW_DIVERTED },
        { label: 'Einspritzschaltung', value: HeatingCircuitTemperatureControlHardware.INJECTION_UNIT },
        { label: 'Durchgangsventil', value: HeatingCircuitTemperatureControlHardware.INLINE_VALVE },
        { label: 'Doppelte Beimischschaltung', value: HeatingCircuitTemperatureControlHardware.DOUBLE_ADMIX },
        { label: 'Einfache Beimischschaltung', value: HeatingCircuitTemperatureControlHardware.SINGLE_ADMIX },
        { label: 'Ungemischt', value: HeatingCircuitTemperatureControlHardware.UNMIXED },
      ],
      heatingCircuitRadiatorOptions: [
        { label: 'Normale Heizkörper', value: HeatingCircuitRadiatorTypes.CONVENTIONAL_RADITOR },
        { label: 'Lüftungsanlage', value: HeatingCircuitRadiatorTypes.VENTILATION },
        { label: 'Fußbodenheizung', value: HeatingCircuitRadiatorTypes.UNDERFLOOR_HEATING },
      ],
      heatingCircuitControlParameterOptions: [
        { label: 'Automatisch', value: HeatingCircuitTypes.AUTOMATIC },
        { label: 'Parametrisierte Kurve', value: HeatingCircuitTypes.PARAMETERIZED_CURVE },
        { label: 'Kurve auf Basis von Eckpunkten', value: HeatingCircuitTypes.POINTED_CURVE },
        { label: 'Konstante Temperatur', value: HeatingCircuitTypes.CONSTANT_TEMPERATURE },
      ],
      teleHeatingOptions: [
        {
          label: 'Einfach',
          value: TeleHeatingType.SINGLE,
        },
        {
          label: 'Doppelt',
          value: TeleHeatingType.DUAL,
        },
        {
          label: 'Direkt',
          value: TeleHeatingType.DIRECT,
        },
      ],
      teleHeatingTemperatureControlOptions: [
        {
          label: 'Kurve auf Basis von Eckpunkten',
          value: 'pointed_curve_parameter',
        },
        {
          label: 'Konstante Temperatur',
          value: 'constant_temperature',
        },
      ],
      form: {
        boiler: {
          condensing: null,
          wall_mounted: false,
          return_temp_uplift: false,
          safety_temp_guard: 95,
          safety_temp_limiter: 110,
          cascade: false,
          is_modulating: true,
          has_own_pump: true,
          has_regulating_valve: false,
          has_check_valve: false,
          system_separation: false,
          power_max_out: 0,
          power_min_out: 0,
        },
        waterHeating: {
          type_of_pwh: null,
          potable_water_target_temp: null,
          potable_water_temperature_tolerance: null,
          circulation_target_temp: null,
          circulation_temperature_tolerance: null,
        },
        buffer: {
          type: BufferTypes.HEAT_ENERGY_STORAGE,
          upper_limit: null,
          lower_limit: null,
          prim_port_energy_input: null,
          sec_port_energy_input: null,
          tert_port_energy_input: null,
          quart_port_energy_input: null,
          two_sensors_controlled: null,
        },
        heatingCircuit: {
          control_parameter: {
            type: null,
            summer_shutdown_temp: null,
            nightly_reduced_temperature: null,
            minimum_temperature: 60,
            maximum_temperature: null,
            supply_potable_water_heating: false,
            room_target_temperature: null,
            offset: null,
            slope: null,
            target_temperature: null,
            env_flow_temp_points: [[0, 0]],
          },
          radiator_type: HeatingCircuitRadiatorTypes.CONVENTIONAL_RADITOR,
          heating_circuit_building_year: null,
          temperature_control_hardware: HeatingCircuitTemperatureControlHardware.SINGLE_ADMIX,
          execute_oscillation_analysis: true,
        },
        teleHeating: {
          system_separation: true,
          teleheating_type: TeleHeatingType.SINGLE,
          safety_temp_guard: null,
          safety_temp_limiter: null,
          temperature_control_type: null,
          flow_temperature_control_parameter: {
            type: null,
            target_temperature: null,
            pointed_curve_parameter: [[0, 0]],
          },
          return_temperature_control_parameter: {
            type: null,
            target_temperature: null,
            pointed_curve_parameter: [[0, 0]],
          },
        },
      } as HeatingSystemMetadata,
      requestType: 'create' as 'create' | 'edit',
      lastChange: {
        user: null as string | null,
        date: null as Date | null,
      },
      timer: null as number | null,
      errors: [] as ErrorMessage[],
      isProcessing: false,
    };
  },
  computed: {
    ...mapGetters(['openModal']),
    heatingSystems(): HeatingSystem[] {
      return this.$store.getters.heatingSystems;
    },
    heatingSystemsComponents(): HeatingSystemComponent[] {
      return this.$store.getters.heatingSystemsComponents;
    },
    componentType(): string {
      return this.activeHeatingSystemComponent?.type || '';
    },
    activeHeatingSystem(): HeatingSystem | undefined {
      return this.$store.getters.activeHeatingSystem;
    },
    activeHeatingSystemComponent(): HeatingSystemComponent | undefined {
      return this.$store.getters.activeHeatingSystemComponent;
    },
    openApplySettings(): boolean {
      return this.$store.getters.openApplySettings;
    },
    formData(): HeatingSystemMetadata {
      return this.$store.getters.formData;
    },
    heatingSystemsMetadata(): HeatingSystemMetadataResponse[] {
      return this.$store.getters.heatingSystemsMetadata;
    },
    idsToApplySettings(): string[] {
      return this.$store.getters.idsToApplySettings;
    },
  },
  watch: {
    meta: {
      immediate: true,
      deep: true,
      handler(val: HeatingSystemMetadataKey) {
        this.populateFormData(val);
      },
    },
  },
  mounted() {
    if (this.activeHeatingSystem?.metadata) {
      this.form = this.activeHeatingSystem.metadata;
    }
  },
  methods: {
    closeModal() {
      this.$store.commit('openModal', false);
    },
    onCopyMetadata() {
      this.$store.commit('openApplySettings', !this.openApplySettings);
    },
    async saveForm() {
      this.$store.commit('setFormData', this.form);
      this.validateForm();
      if (this.errors.length > 0) {
        // show error messages
        this.$store.dispatch('ADD_TOAST_MESSAGES', {
          messages: this.errors.map(({ message }) => ({ text: message, class: 'error' })),
          duration: 10000,
        });
        // close "apply settings" panel if it was open
        this.$store.commit('openApplySettings', false);
      } else {
        // save data
        try {
          this.isProcessing = true;
          const componentType = this.activeHeatingSystemComponent?.type;
          const spotId = this.activeHeatingSystemComponent?.value;
          const heatingSystemId = this.activeHeatingSystem?.components.id;
          if (componentType && spotId) {
            const metadata = this.getFormData(componentType);
            if (this.requestType === 'create') {
              // create new metadata entry
              await this.$store.dispatch('addHeatingSystemMetadata', {
                spotId,
                heatingSystemId,
                componentType,
                metadata,
              });
            } else {
              // update metadata entry
              await this.$store.dispatch('updateHeatingSystemMetadata', {
                spotId,
                heatingSystemId,
                componentType,
                metadata,
              });
            }

            if (metadata) {
              this.saveApplySettings(metadata);
            }

            this.$emit('form-saved');

            this.$store.dispatch('ADD_TOAST_MESSAGES', {
              messages: [{ text: 'Metadaten erfolgreich gespeichert', class: 'success' }],
            });
          }
        } catch (error) {
          this.$store.dispatch('ADD_TOAST_MESSAGES', {
            messages: [{ text: 'There was a problem saving metadata', class: 'error' }],
          });
        } finally {
          this.isProcessing = false;
        }
      }
    },
    saveApplySettings(metadata: HeatingSystemMetadataKey) {
      this.idsToApplySettings.forEach((id) => {
        // get the heating system data
        const hs = this.heatingSystems.find((item) => item.components.id === id);
        hs?.components.heatingSystemMeasurementGroups.forEach((group) => {
          const componentType = group.systemComponentTypeName;
          // NB: all measurements are using the same spotId
          const spotId = group.heatingSystemMeasurements[0]?.spotId ?? null;
          // apply/copy settings only if the component type is the same
          if (this.componentType === componentType) {
            if (this.metadataExists(id, spotId)) {
              // update settings
              this.$store.dispatch('updateHeatingSystemMetadata', {
                spotId,
                heatingSystemId: id,
                componentType,
                metadata,
              });
            } else {
              // add settings
              this.$store.dispatch('addHeatingSystemMetadata', {
                spotId,
                heatingSystemId: id,
                componentType,
                metadata,
              });
            }
          }
        });
      });
    },
    metadataExists(heatingSystemId: string, spotId: string): boolean {
      return this.heatingSystemsMetadata.find(
        (item) => item.heatingsystemId === heatingSystemId && item.spotId === spotId,
      )
        ? true
        : false;
    },
    getFormData(componentType: string) {
      switch (componentType) {
        case 'BOILER':
          return this.formData['boiler'];
        case 'BUFFER':
          return this.formData['buffer'];
        case 'WARM_HEATING':
          return this.formData['waterHeating'];
        case 'HEATING_CIRCUIT':
          return this.formData['heatingCircuit'];
        case 'TELE_HEATING':
          return this.formData['teleHeating'];
        default:
          return null;
      }
    },
    onNumberFieldChange(field: string, value: number) {
      // debounce the input value
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = window.setTimeout(() => {
        this.validateNumber(field, value);
      }, 800);
    },
    // eslint-disable-next-line complexity
    validateForm(): void {
      this.errors = [];
      switch (this.componentType) {
        case 'BOILER':
          for (const [key, value] of Object.entries(this.form.boiler)) {
            const componentKey = `boiler.${key}`;
            if (key === 'condensing') {
              this.validateSelection(componentKey, value);
            }
            const requiredNumberFields = ['safety_temp_guard', 'safety_temp_limiter', 'power_min_out', 'power_max_out'];
            if (requiredNumberFields.includes(key)) {
              this.validateNumber(componentKey, value);
            }
          }
          break;
        case 'WARM_HEATING':
          for (const [key, value] of Object.entries(this.form.waterHeating)) {
            const componentKey = `waterHeating.${key}`;
            if (key === 'type_of_pwh') {
              this.validateSelection(componentKey, value);
            }
            const requiredNumberFields = [
              'potable_water_target_temp',
              'potable_water_temperature_tolerance',
              'circulation_target_temp',
              'circulation_temperature_tolerance',
            ];
            if (requiredNumberFields.includes(key)) {
              this.validateNumber(componentKey, value);
            }
          }
          break;
        case 'BUFFER':
          for (const [key, value] of Object.entries(this.form.buffer)) {
            const componentKey = `buffer.${key}`;
            const requiredNumberFields = ['upper_limit', 'lower_limit'];
            if (requiredNumberFields.includes(key)) {
              this.validateNumber(componentKey, value);
            }
          }
          break;
        case 'HEATING_CIRCUIT':
          // validate "control parameter" fields
          for (const [key, value] of Object.entries(this.form.heatingCircuit.control_parameter)) {
            if (key === 'type' && !value) {
              this.errors.push({
                field: key,
                message: 'Regelungsparameter ist erforderlich',
              });
            }
            const type = this.form.heatingCircuit.control_parameter?.type;
            if (type === 'pointed_curve' && key === 'env_flow_temp_points' && typeof value === 'object') {
              if (Array.isArray(value) && value.length < 2) {
                this.errors.push({
                  field: key,
                  message: 'Bitte geben Sie mindestens 2 Wertepaare an',
                });
              } else if (!this.isTemperatureRangeValid(value)) {
                this.errors.push({
                  field: key,
                  message: 'Vorlauftemperaturpunkte der Umgebung muss zwischen -100 und 100 °C liegen',
                });
              } else if (value && !this.hasUniqueValues(value)) {
                this.errors.push({
                  field: key,
                  message: 'Jeder Eintrag zur Umgebungstemperatur muss eindeutig sein',
                });
              }
            }
          }
          break;
        case 'TELE_HEATING':
          for (const [key, value] of Object.entries(this.form.teleHeating)) {
            const componentKey = `teleHeating.${key}`;
            const requiredNumberFields = ['safety_temp_guard', 'safety_temp_limiter'];
            if (requiredNumberFields.includes(key)) {
              this.validateNumber(componentKey, value);
            }
          }

          this.validateTeleHeatingControlParameters(
            this.form.teleHeating.flow_temperature_control_parameter,
            'flow_temperature_control_parameter',
          );
          this.validateTeleHeatingControlParameters(
            this.form.teleHeating.return_temperature_control_parameter,
            'return_temperature_control_parameter',
          );
          break;
      }
    },
    // validate "ja/nein" options, check is if value is null.
    validateSelection(field: string, value: number | string | boolean | null) {
      switch (field) {
        case 'boiler.condensing':
          if (!value) {
            this.errors.push({ field, message: 'Brennwertgerät ist erforderlich' });
          }
          break;
        case 'waterHeating.type_of_pwh':
          if (!value) {
            this.errors.push({ field, message: 'Art der Warmwasserbereitung ist erforderlich' });
          }
          break;
      }
    },
    // validate number fields, check if value is not on business rules range.
    // eslint-disable-next-line complexity
    validateNumber(field: string, value: any) {
      switch (field) {
        // boiler
        case 'boiler.safety_temp_guard':
          if (value === null || value < 80 || value > 105) {
            this.errors.push({ field, message: 'Sicherheitstemperaturwächter sollte zwischen 80 und 105 liegen' });
          }
          break;
        case 'boiler.safety_temp_limiter':
          if (value === null || value < 100 || value > 120) {
            this.errors.push({ field, message: 'Sicherheitstemperaturbegrenzer sollte zwischen 100 und 120 liegen' });
          }
          break;
        case 'boiler.power_min_out':
        case 'boiler.power_max_out':
          if (value === null || value < 0) {
            this.errors.push({ field, message: `${this.getFieldNameByKey(field)} muss gleich oder größer als 0 sein` });
          }
          break;
        // water heating
        case 'waterHeating.potable_water_target_temp':
        case 'waterHeating.potable_water_temperature_tolerance':
        case 'waterHeating.circulation_target_temp':
        case 'waterHeating.circulation_temperature_tolerance':
          if (value === null || value < 0 || value > 100) {
            this.errors.push({
              field,
              message: `${this.getFieldNameByKey(field)} sollten zwischen 0 und 100 liegen.`,
            });
          }
          break;
        // buffer
        case 'buffer.upper_limit':
        case 'buffer.lower_limit':
          if (value === null || value < 0) {
            this.errors.push({
              field,
              message: `${this.getFieldNameByKey(field)} muss gleich oder größer als 0 sein.`,
            });
          }
          break;
        // heating circuit
        case 'heatingCircuit.heating_circuit_building_year':
          if (value === null || value < 1 || String(Number(value)).length !== 4) {
            this.errors.push({
              field,
              message: `${this.getFieldNameByKey(field)} muss ein gültiges Jahr sein.`,
            });
          }
          break;
        case 'heatingCircuit.control_parameter.summer_shutdown_temp':
        case 'heatingCircuit.control_parameter.nightly_reduced_temperature':
        case 'heatingCircuit.control_parameter.minimum_temperature':
        case 'heatingCircuit.control_parameter.maximum_temperature':
          if (value === null || !Number.isInteger(value)) {
            this.errors.push({
              field,
              message: `${this.getFieldNameByKey(field)} muss eine Zahl sein`,
            });
          }
          break;
        // tele heating
        case 'teleHeating.safety_temp_guard':
        case 'teleHeating.safety_temp_limiter':
          if (!value || Number(value) < 0 || Number(value) > 130) {
            this.errors.push({
              field,
              message: `${this.getFieldNameByKey(field)} sollten zwischen 0 und 130 liegen.`,
            });
          }
          break;
        case 'teleHeating.flow_temperature_control_parameter.target_temperature':
          if (!value || Number(value) < 0 || Number(value) > 130) {
            this.errors.push({
              field,
              message: `${this.getFieldNameByKey(field)} sollten zwischen 0 und 130 liegen.`,
            });
          }
          break;
        case 'teleHeating.return_temperature_control_parameter.target_temperature':
          if (!value || Number(value) < 0 || Number(value) > 80) {
            this.errors.push({
              field,
              message: `${this.getFieldNameByKey(field)} sollten zwischen 0 und 80 liegen.`,
            });
          }
          break;
      }
    },
    validateTeleHeatingControlParameters(
      { pointed_curve_parameter, target_temperature, type }: TemperatureControlParameter,
      field: string,
    ): void {
      if (type === 'pointed_curve_parameter') {
        if (Array.isArray(pointed_curve_parameter) && pointed_curve_parameter.length < 2) {
          this.errors.push({
            field,
            message: 'Bitte geben Sie mindestens 2 Wertepaare an',
          });
        } else if (!this.isTemperatureRangeValid(pointed_curve_parameter, 0, 200, -100, 100)) {
          this.errors.push({
            field,
            message:
              'Soll-Vorlauftemperatur müssen zwischen 0 und 200 °C und die Umgebungstemperatur zwischen -100 und 100 °C liegen.',
          });
        } else if (pointed_curve_parameter && !this.hasUniqueValues(pointed_curve_parameter)) {
          this.errors.push({
            field,
            message: 'Jeder Eintrag zur Umgebungstemperatur muss eindeutig sein',
          });
        }
      } else if (type === 'constant_temperature') {
        this.validateNumber(`teleHeating.${field}.target_temperature`, target_temperature);
      }
    },
    isTemperatureRangeValid(
      data: number[][] | null,
      minTarget = -100,
      maxTarget = 100,
      minEnv = 0,
      maxEnv = 200,
    ): boolean {
      if (!data) return false;
      return data.every(
        (obj) =>
          Number(obj[0]) >= minTarget &&
          Number(obj[0]) <= maxTarget &&
          Number(obj[1]) >= minEnv &&
          Number(obj[1]) <= maxEnv,
      );
    },
    // eslint-disable-next-line complexity
    getFieldNameByKey(key: string): string {
      switch (key) {
        case 'waterHeating.potable_water_target_temp':
          return 'Solltemperatur Warmwasser';
        case 'waterHeating.potable_water_temperature_tolerance':
          return 'Erlaubte Toleranz der Warmwasser Temperatur';
        case 'waterHeating.circulation_target_temp':
          return 'Solltemperatur Zirkulation';
        case 'waterHeating.circulation_temperature_tolerance':
          return 'Zulässige Toleranz der Zirkulationstemperatur';
        case 'buffer.upper_limit':
          return 'Obere Solltemperatur';
        case 'buffer.lower_limit':
          return 'Untere Solltemperatur';
        case 'boiler.power_min_out':
          return 'Minimale Leistungsabgabe';
        case 'boiler.power_max_out':
          return 'Maximale Leistungsabgabe';
        case 'heatingCircuit.heating_circuit_building_year':
          return 'Baujahr des versorgten Gebäudes';
        case 'heatingCircuit.control_parameter.summer_shutdown_temp':
          return 'Sommer-Abschalttemperatur';
        case 'heatingCircuit.control_parameter.nightly_reduced_temperature':
          return 'Nächtliche Temperaturabsenkung';
        case 'heatingCircuit.control_parameter.minimum_temperature':
          return 'Minimale Temperatur';
        case 'heatingCircuit.control_parameter.maximum_temperature':
          return 'Maximale Temperatur';
        case 'teleHeating.safety_temp_guard':
          return 'Sicherheitstemperaturwächter';
        case 'teleHeating.safety_temp_limiter':
          return 'Sicherheitstemperaturbegrenzer';
        case 'teleHeating.flow_temperature_control_parameter.target_temperature':
        case 'teleHeating.return_temperature_control_parameter.target_temperature':
          return 'Zieltemperatur';
        default:
          return '';
      }
    },
    addTemperatureField(context: string): void {
      const defaultPoints = [0, 0];
      if (context === 'heating-circuit') {
        this.form.heatingCircuit.control_parameter.env_flow_temp_points?.push(defaultPoints);
      }
      if (context === 'tele-heating-flow') {
        this.form.teleHeating.flow_temperature_control_parameter.pointed_curve_parameter?.push(defaultPoints);
      }
      if (context === 'tele-heating-return') {
        this.form.teleHeating.return_temperature_control_parameter.pointed_curve_parameter?.push(defaultPoints);
      }
    },
    removeTemperatureField(index: number, context: string): void {
      if (context === 'heating-circuit') {
        const points = this.form.heatingCircuit.control_parameter.env_flow_temp_points;
        if (points && points.length > 0) {
          this.form.heatingCircuit.control_parameter.env_flow_temp_points = points.filter((point, idx) => {
            return idx !== index;
          });
        }
      }
      if (context === 'tele-heating-flow') {
        const points = this.form.teleHeating.flow_temperature_control_parameter.pointed_curve_parameter;
        if (points && points.length > 0) {
          this.form.teleHeating.flow_temperature_control_parameter.pointed_curve_parameter = points.filter(
            (point, idx) => {
              return idx !== index;
            },
          );
        }
      }
      if (context === 'tele-heating-return') {
        const points = this.form.teleHeating.return_temperature_control_parameter.pointed_curve_parameter;
        if (points && points.length > 0) {
          this.form.teleHeating.return_temperature_control_parameter.pointed_curve_parameter = points.filter(
            (point, idx) => {
              return idx !== index;
            },
          );
        }
      }
    },
    // this function checks if the items array of objects has unique "environment" values.
    hasUniqueValues(items: number[][]): boolean {
      const seen = new Set();
      for (const item of items) {
        if (seen.has(item[1])) {
          return false;
        }
        seen.add(item[1]);
      }
      return true;
    },
    populateFormData(metadata: any) {
      if (metadata && Object.keys(metadata).length) {
        this.requestType = 'edit';
        switch (this.componentType) {
          case 'BOILER':
            this.form.boiler = metadata;
            break;
          case 'WARM_HEATING':
            this.form.waterHeating = metadata;
            break;
          case 'BUFFER':
            this.form.buffer = metadata;
            break;
          case 'TELE_HEATING':
            this.form.teleHeating = {
              ...metadata,
              flow_temperature_control_parameter: {
                ...metadata.flow_temperature_control_parameter,
                pointed_curve_parameter: metadata.flow_temperature_control_parameter.pointed_curve_parameter ?? [
                  [0, 0],
                ],
              },
              return_temperature_control_parameter: {
                ...metadata.return_temperature_control_parameter,
                pointed_curve_parameter: metadata.return_temperature_control_parameter.pointed_curve_parameter ?? [
                  [0, 0],
                ],
              },
            };
            break;
          case 'HEATING_CIRCUIT':
            this.form.heatingCircuit = metadata;
            break;
        }
      } else {
        this.requestType = 'create';
      }
    },
  },
});
