





















































import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { defineComponent, ref } from '@vue/composition-api';
import GroupsMixin from '@/features/core/components/mixins/groups';
import Flex from '@/features/ui/layout/Flex.global.vue';
import Cell from '@/features/ui/layout/Cell.global.vue';
import Panel from '@/features/ui/panel/Panel.global.vue';
import Pagination from '@/features/ui/pagination/Pagination.global.vue';
import getProjectItemStatusAuditLogs from '../graphql/getProjectItemStatusAuditLogs.gql';
import { transformStatusAuditLogsToTableData } from '@/features/app-enterprise-project/views/enterprise-project-audit-logs/helpers/draft-audit-log-processing';
import Datepicker from '@/components/datepicker/Datepicker.global.vue';
import InputSelect from '@/features/ui/inputs/InputSelect.global.vue';
import { TreeNodeClassification } from '@/types/iot-portal';
import {
  GetProjectStatusAuditLogs,
  GetProjectStatusAuditLogsVariables,
} from '@/features/app-enterprise-project/views/enterprise-project-audit-logs/graphql/__generated__/GetProjectStatusAuditLogs';
import ProjectItemStatusAuditLogsTable from '@/features/app-enterprise-project/views/enterprise-project-audit-logs/components/ProjectItemStatusAuditLogsTable.vue';
import { GetProjectDetailsQuery_projectDetails_items } from '@/features/app-enterprise-project/views/enterprise-project-details/__generated__/GetProjectDetailsQuery';
import { mapActions } from 'vuex';
import {
  getStartOfDayTimestamp,
  getEndOfDayTimestamp,
  isDateRangeInvalid,
} from '@/features/app-enterprise-project/views/enterprise-project-audit-logs/helpers/date-time-utils';

export default defineComponent<Record<string, unknown>, any, any>({
  name: 'ProjectAuditLogsView',
  components: {
    Flex,
    Cell,
    Panel,
    Pagination,
    Datepicker,
    InputSelect,
    ProjectItemStatusAuditLogsTable,
  },
  apollo: {
    projectItemStatusAuditLogs: {
      query: getProjectItemStatusAuditLogs,
      fetchPolicy: 'network-only',
      variables() {
        return {
          projectId: this.project?.id,
          fromTimestamp: getStartOfDayTimestamp(this.fromDate),
          untilTimestamp: getEndOfDayTimestamp(this.untilDate),
          subjectEntityId: this.selectedBuilding ? this.selectedBuilding : undefined,
          skip: this.skip,
          take: this.take,
        } as GetProjectStatusAuditLogsVariables;
      },
      result({ data }: { data: GetProjectStatusAuditLogs }) {
        this.projectStatusAuditLogsForTable = transformStatusAuditLogsToTableData(
          data.projectItemStatusAuditLogs.items,
        );
      },
      skip() {
        // Skip loading until the project ID is available
        return !this.project?.id || !this.projectDetails?.items.length || !this.fromDate || !this.untilDate;
      },
    },
  },
  mixins: [PaginationQueryStringMixin, GroupsMixin],
  props: {
    projectReference: {
      type: String,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      projectList: undefined,
      customerId: undefined,
      // Filtering
      selectedBuilding: '',
      buildingOptions: [],
      projectStatusAuditLogsForTable: [],
      fromDate: null,
      untilDate: null,
    };
  },
  computed: {
    loading(): boolean {
      return this.$apollo.loading;
    },
    take(): number {
      return 12;
    },
    totalPages(): number {
      const pageCount = Math.ceil((this.projectItemStatusAuditLogs?.count ?? 0) / this.take);

      return pageCount;
    },
    skip(): number {
      return (this.currentPage - 1) * this.take;
    },
  },
  mounted() {
    this.currentPage = 1;
    const now = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(now.getMonth() - 1); // Set one month ago
    this.fromDate = oneMonthAgo;
    this.untilDate = now;
    // Build the buildings options for liegenshaft buildings
    this.buildingOptions = this.projectDetails.items.map((item: GetProjectDetailsQuery_projectDetails_items) => ({
      label: `${item.streetAddress} ${item.houseAddress} ${item.houseAddressSuffix ? item.houseAddressSuffix : ''}`,
      value: item.id,
    }));
    // Get the route query params
    const buildingReferenceId = this.$route.query.buildingReferenceId;
    if (buildingReferenceId) {
      // Get the building with the reference ID
      const routeBuilding = this.projectDetails.items.find(
        (building: GetProjectDetailsQuery_projectDetails_items) => building.buildingReferenceId === buildingReferenceId,
      );
      if (routeBuilding) {
        this.selectedBuilding = routeBuilding.id;
      }
    }
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    onPaginationUpdate(pageNumber: number): void {
      this.currentPage = pageNumber;
    },
    async onFromDateChange(update: any): Promise<void> {
      await this.validateAndResetFromDate(update);
    },
    async onUntilDateChange(update: any): Promise<void> {
      await this.validateAndResetUntilDate(update);
    },
    async validateAndResetFromDate(update: any): Promise<void> {
      if (isDateRangeInvalid(this.fromDate, this.untilDate)) {
        await this.addToastMessages({
          messages: [{ text: 'Das Datum von kann nicht nach dem Datum bis liegen.', class: 'error' }],
        });
        this.fromDate = update.oldValue;
      }
    },
    async validateAndResetUntilDate(update: any): Promise<void> {
      if (isDateRangeInvalid(this.fromDate, this.untilDate)) {
        await this.addToastMessages({
          messages: [{ text: 'Das Datum von kann nicht nach dem Datum bis liegen.', class: 'error' }],
        });

        this.untilDate = update.oldValue;
      }
    },
    changeBuildingValue(type: string, value: string): void {
      if (type === 'building') {
        this.selectedBuilding = value;
        // Get the buildingReferenceId for the selected building
        const building = this.projectDetails.items.find(
          (item: GetProjectDetailsQuery_projectDetails_items) => item.id === value,
        );
        if (building) {
          this.$router.replace({ query: { ...this.$route.query, buildingReferenceId: building.buildingReferenceId } });
        } else {
          this.$router.replace({ query: { ...this.$route.query, buildingReferenceId: undefined } });
        }
      }
    },
  },
});
