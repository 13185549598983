var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.projectDetails && _vm.projectDetails.items.length === 0 && !_vm.loading)?_c('FormMessage',[_vm._v("Keine Daten gefunden")]):_c('Table',{staticClass:"project-items-table",attrs:{"ready":!_vm.loading,"columns":_vm.columns,"rows":_vm.projectObjectList,"data-element-id":'app-enterprise-project-project-details-table'},scopedSlots:_vm._u([{key:"buildingReferenceId",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.buildingReferenceId)+" ")]}},{key:"address",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.streetAddress)+" "+_vm._s(row.houseAddress)+" "+_vm._s(row.houseAddressSuffix)+" "+_vm._s(row.postCodeAddress)+" "+_vm._s(row.cityAddress)+" ")]}},{key:"buildingType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getBuildingLetter(row.buildingType))+" ")]}},{key:"customizedId",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.customizedId)+" ")]}},{key:"productType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.productType)+" ")]}},{key:"useCase",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.useCases)+" ")]}},{key:"mainService",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getMainServiceText(row.mainService))+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(_vm.shouldShowStatusDropdown(row.buildingType))?_c('InputSelect',{attrs:{"value":_vm.getRowStatus(row),"options":_vm.statusOptions(row),"reduce":function (ref) {
	var value = ref.value;

	return value;
},"disabled":_vm.disableStatusUpdate(row),"data-element-id":'app-enterprise-project-project-details-object-status-update-selector',"clearable":false},on:{"update":function($event){return _vm.onObjectStatusUpdate($event, row)}}}):_c('div')]}},{key:"gatewayIdentifier",fn:function(ref){
var row = ref.row;
return _vm._l((row.gatewayDetails),function(gatewayIdentifier){return _c('div',{key:gatewayIdentifier.identifier,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goToGateway(gatewayIdentifier)}}},[(_vm.isLGateway(_vm.getGatewayIdentifier(gatewayIdentifier)))?_c('div',[_vm._v(" "+_vm._s(_vm.getGatewayIdentifier(gatewayIdentifier))+" ")]):_vm._e()])})}},{key:"actions",fn:function(ref){
var row = ref.row;
return [(row.buildingType === 'Liegenschaft')?_c('div',[(_vm.projectDraft)?_c('WizardButton',{attrs:{"disabled":!_vm.canEditProjectItem(row) || _vm.loading},on:{"click":function($event){return _vm.editPropertyBuilding(row)}}},[_vm._v("bearbeiten")]):_vm._e(),_c('PropertyEditModal',{ref:"propertyEditModal",on:{"confirm":function($event){return _vm.reloadData()}}})],1):_vm._e()]}},{key:"lastUpdate",fn:function(ref){
var row = ref.row;
return [(row.lastUpdate)?_c('Cell',[_c('div',{staticClass:"text-overflow"},[_vm._v(" "+_vm._s(_vm.transformToDateOnly(row.lastUpdate.lastUpdatedTimestamp))+", "+_vm._s(_vm.transformToTimeOnly(row.lastUpdate.lastUpdatedTimestamp))+" / "+_vm._s(_vm.getUserName(row.lastUpdate.lastUpdatedByUser))+" ")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }