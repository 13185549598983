





























import { defineComponent } from '@vue/composition-api';
import { mapState, mapActions, mapGetters } from 'vuex';
import InputText from '@/features/ui/inputs/InputText.global.vue';
import { doesBuildingHaveInfo } from '@/features/app-enterprise-project/views/enterprise-project-create/components/step3/step-3-helpers';
import { InputValidationMixin } from '@/features/core/components/mixins/input-validation';
import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';

export default defineComponent<any, Record<string, unknown>, any, any, any>({
  components: {
    InputText,
  },
  mixins: [InputValidationMixin],
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapGetters(['currentTab', 'buildingsWithElevators', 'projectType']),
    ...mapState({
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      activeStep2BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep2BuildingId,
      buildingsCreated: (state: any) => state.EnterpriseProjectCreateModule.buildingsCreated,
      editableBuildings: (state: any) => state.EnterpriseProjectCreateModule.editableBuildings,
      tab: (state: any) => state.EnterpriseProjectCreateModule.tab,
    }),
    liegenshaftBuildings() {
      return this.buildings.filter((building: any) => building.liegenshaft);
    },
    filteredBuildings() {
      let buildings = this.liegenshaftBuildings;
      if (this.currentTab === 'elevatorSelection') {
        buildings = this.buildingsWithElevators;
      }
      if (this.currentTab === 'comments') {
        buildings = buildings.filter((building: any) => building.includesComments);
      }
      return buildings.filter((building: any) => building.name.toLowerCase().includes(this.search.toLowerCase()));
    },
  },
  methods: {
    ...mapActions(['setActiveStep2BuildingId']),
    setActiveBuilding(id: number) {
      this.setActiveStep2BuildingId(id);
      this.$emit('active-building-updated');
      if (this.tab === 'comments') {
        this.$store.commit('setCommentTabActiveBuildingId', id);
      }
    },
    validateBuildingInformationForm(building: ProjectBuilding) {
      return (
        this.validateEmail(building.liegenshaftInformation?.email) &&
        building.liegenshaftInformation?.phoneNumber &&
        building.liegenshaftInformation?.firstName &&
        building.liegenshaftInformation?.surname &&
        building.liegenshaftInformation?.title
      );
    },
    // eslint-disable-next-line complexity
    getListItemSubText(building: ProjectBuilding): string {
      if (this.buildingsCreated && !this.editableBuildings.has(`${building.buildingId}`)) {
        // uneditable building in a project where buildings are already created
        return 'nicht editierbar';
      }
      if (building.buildingId === this.activeStep2BuildingId) {
        return 'in Bearbeitung';
      }
      if (this.tab === 'comments') {
        // If the building has an elevator, we show the status as "ändern"
        if (building.comment) {
          return 'ändern';
        }
      }
      if (this.tab === 'elevatorSelection') {
        // If the building has an elevator, we show the status as "ändern"
        if (building.hasElevator) {
          return 'ändern';
        }
        // If one of it's children has an elevator, we show the status as "ändern"
        if (this.buildings.some((b: ProjectBuilding) => b.parentProperty === building.buildingId && b.hasElevator)) {
          return 'ändern';
        }
      }
      if (this.tab === 'buildingContactInformation' && this.validateBuildingInformationForm(building)) {
        return 'ändern';
      }

      // Check if building is the parent of other buildings
      if (
        this.tab === 'step2' &&
        this.buildings.some((b: ProjectBuilding) => b.parentProperty === building.buildingId)
      ) {
        return 'ändern';
      }

      // If on step 3 we check if the building has the required information
      if (this.tab === 'step3' && doesBuildingHaveInfo(building, this.projectType)) {
        return 'ändern';
      }

      return 'offen';
    },
  },
});
