import ExcelJS from 'exceljs';
import { GetProjectQuery_project } from '../__generated__/GetProjectQuery';
import ApolloClient, { ApolloQueryResult } from 'apollo-client';
import {
  GetProjectDetailsExportQuery,
  GetProjectDetailsExportQuery_projectDetails_items,
  GetProjectDetailsExportQuery_projectDetails_items_customerContacts,
  GetProjectDetailsExportQuery_projectDetails_items_useCaseList,
  GetProjectDetailsExportQueryVariables,
} from '../__generated__/GetProjectDetailsExportQuery';
import projectDetailExportQuery from '../project-details-export.gql';
import { setBicabColumns, setGk40Columns, styleBicarbSheet, styleGk4NullSheet, StyleOptions } from './style-xlsx';
import {
  BICAB_HEADER_ROW,
  BICAB_HEADER_ROW_2,
  defaultSpreadsheetProtectionOptions,
  ENTERPRISE_PRODUCT_OPTION_MAP,
  HEADER_ROW,
  HEADER_ROW_2,
  LIEGENSCHAFT_GW_REXEP,
  useCasesForProjectChangesText,
} from './standortlists-constants';
import {
  EnterpriseProjectObjectProduct,
  EnterpriseProjectObjectProductOption,
  EnterpriseProjectObjectUseCase,
  EnterpriseProjectServiceType,
  EnterpriseProjectType,
  TreeNodeClassification,
} from '@/types/iot-portal';
import {
  NewProjectData,
  ProjectBuilding,
  ProjectDraftCompleted,
} from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';
import { EnterpriseProjectCopyOption } from '@/features/app-enterprise-project/views/enterprise-project-details/project-details-constants';

type UseCase = GetProjectDetailsExportQuery_projectDetails_items_useCaseList;
type CustomerContact = GetProjectDetailsExportQuery_projectDetails_items_customerContacts;
type ProjectObjectDetails = GetProjectDetailsExportQuery_projectDetails_items;
type ProjectDraft = NewProjectData & ProjectDraftCompleted;

interface CustomerInfo {
  customerName: string | null;
  customerNumber: string | null;
  invoiceNumber: string | null;
  contractTerm: number | null;
  createNewInvoiceNumber: boolean;
  referenceToMainContract: boolean;
  transferToMainContract: boolean;
  comment: string | null;
}

function getExcelProductType(
  object: ProjectObjectDetails,
  option?: EnterpriseProjectObjectProductOption | null,
): string {
  if (option && object.buildingType === TreeNodeClassification.Liegenschaft) {
    return ENTERPRISE_PRODUCT_OPTION_MAP[option];
  }
  return '';
}

function getExcelBuildingType(buildingType: string): string {
  if (buildingType === TreeNodeClassification.Liegenschaft) {
    return 'Liegenschaft (Hauptgebäude)';
  }
  if (buildingType === TreeNodeClassification.Gebaude) {
    return 'Gebäude';
  }
  return '';
}

function getNumberOfApartmentUnits(
  property: ProjectObjectDetails,
  directory: ProjectObjectDetails | null,
): number | null {
  if (property.buildingType == TreeNodeClassification.Liegenschaft) {
    return directory?.numberOfApartments ?? null;
  }

  return property.numberOfApartments;
}

function formatMainService(mainService: EnterpriseProjectServiceType | null): string | null {
  if (!mainService) {
    return null;
  }

  if (mainService == EnterpriseProjectServiceType.GK_HUB_L_CABLE) {
    return 'GK_HUB_L_Kabel';
  }

  return mainService?.toString();
}

function formatCustomerContact(customerContact: CustomerContact | undefined): string {
  let name = '';
  if (customerContact?.title) {
    name += `${customerContact.title} `;
  }
  name = name.trimStart();

  if (customerContact?.firstName) {
    name += `${customerContact.firstName} `;
  }
  name = name.trimStart();

  if (customerContact?.surname) {
    name += `${customerContact.surname}`;
  }
  name = name.trim();

  return name;
}

function getUseCaseObject(useCaseList: UseCase[], useCaseType: string): UseCase | undefined {
  return useCaseList?.find((useCase) => useCase.useCaseType === useCaseType);
}

type UseCaseColumns = [string, string | number, string | number];

function getUseCaseColumns(property: ProjectObjectDetails, useCase: UseCase | undefined): UseCaseColumns {
  if (!useCase) {
    if (property.buildingType == TreeNodeClassification.Liegenschaft) {
      return ['nein', '-', '-'];
    } else {
      return ['', '-', '-'];
    }
  }

  return ['ja', useCase.oneTimePriceInEuros ?? '-', useCase.monthlyPriceInEuros ?? '-'];
}

function formatInstallationDate(date: string): string {
  const dateValue = new Date(date);

  return dateValue.toLocaleDateString();
}

function formatContractTerm(customerInfo: CustomerInfo): string {
  const yearDescription = customerInfo.contractTerm == 1 ? 'Jahr' : 'Jahre';
  let formattedContractTerm = customerInfo.contractTerm ? `${customerInfo.contractTerm} ${yearDescription}` : '';

  if (customerInfo.referenceToMainContract) {
    formattedContractTerm += ' (Bezug auf Hauptvertrag)';
  } else if (customerInfo.transferToMainContract) {
    formattedContractTerm += ' (Übernahme in Hauptvertrag)';
  }

  return formattedContractTerm;
}

function formatProductUsageGroup(value: string | undefined): string | null {
  if (!value) {
    return null;
  }

  const formatted = value.replace(/^\w+\s-\s/, '');
  return formatted;
}

function getLocation(property: ProjectObjectDetails): string {
  const houseAddressSuffix = property.houseAddressSuffix ?? '';
  let location = `${property.streetAddress} ${property.houseAddress} ${houseAddressSuffix}`.trim();
  if (location) {
    location += ', ';
  }
  location += `${property.postCodeAddress} ${property.cityAddress}`.trim();
  return location;
}

function getPropertyElevatorUseCases(
  property: ProjectObjectDetails,
  parentProperty: ProjectObjectDetails | null,
): {
  elevatorMonitoringUseCase: GetProjectDetailsExportQuery_projectDetails_items_useCaseList | undefined;
  smartElevatorMaintenanceUseCase: GetProjectDetailsExportQuery_projectDetails_items_useCaseList | undefined;
} {
  let useCaseList: GetProjectDetailsExportQuery_projectDetails_items_useCaseList[] = [];
  if (property.buildingType === TreeNodeClassification.Liegenschaft && property.attributes?.hasElevator) {
    useCaseList = property.useCaseList ?? [];
  } else if (
    property.buildingType === TreeNodeClassification.Gebaude &&
    property.attributes?.hasElevator &&
    parentProperty
  ) {
    useCaseList = parentProperty.useCaseList ?? [];
  }

  const elevatorMonitoringUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.ELEVATOR_MONITORING);
  const smartElevatorMaintenanceUseCase = getUseCaseObject(
    useCaseList,
    EnterpriseProjectObjectUseCase.SMART_ELEVATOR_MAINTENANCE,
  );

  return { elevatorMonitoringUseCase, smartElevatorMaintenanceUseCase };
}

function addStandortlisteRow(
  worksheet: ExcelJS.Worksheet,
  property: ProjectObjectDetails,
  parentProperty: ProjectObjectDetails | null,
  gatewayIdentifier: string,
  draftBuilding: ProjectBuilding | null,
  directory: ProjectObjectDetails | null,
): void {
  const generateUseCaseColumns = (useCase?: UseCase): UseCaseColumns => getUseCaseColumns(property, useCase);

  const generatePreInspectionColumn = (preInspection: boolean | undefined | null): string => {
    if (property.buildingType !== TreeNodeClassification.Liegenschaft) {
      return '';
    }

    const selection = preInspection ? 'ja' : 'nein';

    return selection;
  };

  const infrastructureVariant = property.products.find(
    ({ productType }) => productType === EnterpriseProjectObjectProduct.INFRASTRUCTURE_VARIANT,
  );
  const iwCloud = property.products.find(({ productType }) => productType === EnterpriseProjectObjectProduct.IW_CLOUD);
  const customerContact = property.customerContacts[0];
  const useCaseList = property.useCaseList ?? [];

  const digitizationUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.TGA_DIGITIZATION);
  const heatingMonitoringUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.HEATING_MONITORING);
  const heatingProfessionalUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.HEATING_PROFESSIONAL);
  const zfaUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.REMOTE_METER_READING);
  const tgaMonitoringUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.TGA_MONITORING);
  const tgaIntegrationUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.TGA_INTEGRATION);
  const smgUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.SMG);
  const serviceManagementUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.SERVICE_MANAGEMENT);
  const vdeUseCase = getUseCaseObject(useCaseList, EnterpriseProjectObjectUseCase.CONSUMPTION_DATA_ACQUISITION);
  const uviUseCase = getUseCaseObject(
    useCaseList,
    EnterpriseProjectObjectUseCase.CONSUMPTION_INFORMATION_DURING_THE_YEAR,
  );
  const smokeDetectorMonitoringUseCase = getUseCaseObject(
    useCaseList,
    EnterpriseProjectObjectUseCase.SMOKE_ALARM_MONITORING,
  );

  const { elevatorMonitoringUseCase, smartElevatorMaintenanceUseCase } = getPropertyElevatorUseCases(
    property,
    parentProperty,
  );

  worksheet.addRow([
    getExcelBuildingType(property.buildingType ?? ''),
    property.buildingReferenceId,
    formatProductUsageGroup(directory?.itemName),
    formatMainService(property.mainService),
    gatewayIdentifier,
    infrastructureVariant?.oneTimePriceInEuros,
    infrastructureVariant?.monthlyPriceInEuros,
    property.buildingType === TreeNodeClassification.Liegenschaft ? getLocation(property) : '',
    property.buildingType === TreeNodeClassification.Gebaude ? getLocation(property) : '',
    getExcelProductType(property, infrastructureVariant?.option),
    property.wieNumber ?? '',
    getNumberOfApartmentUnits(property, directory),
    formatCustomerContact(customerContact),
    customerContact?.phoneNumber,
    customerContact?.email,
    draftBuilding?.installationStartDate ? formatInstallationDate(draftBuilding.installationStartDate) : '',
    draftBuilding?.invoiceName ? draftBuilding.invoiceName : '',
    getExcelProductType(property, iwCloud?.option),
    iwCloud?.oneTimePriceInEuros ?? '-',
    iwCloud?.monthlyPriceInEuros ?? '-',
    draftBuilding?.serviceLevel ? draftBuilding.serviceLevel : '',
    generatePreInspectionColumn(draftBuilding?.preInspection),
    '-',
    ...generateUseCaseColumns(digitizationUseCase).slice(0, -1),
    ...generateUseCaseColumns(heatingMonitoringUseCase),
    ...generateUseCaseColumns(heatingProfessionalUseCase),
    ...generateUseCaseColumns(zfaUseCase),
    ...generateUseCaseColumns(tgaMonitoringUseCase),
    ...generateUseCaseColumns(tgaIntegrationUseCase),
    ...generateUseCaseColumns(smgUseCase),
    ...generateUseCaseColumns(serviceManagementUseCase),
    ...generateUseCaseColumns(vdeUseCase),
    ...generateUseCaseColumns(uviUseCase),
    ...generateUseCaseColumns(smokeDetectorMonitoringUseCase),
    ...generateUseCaseColumns(elevatorMonitoringUseCase),
    ...generateUseCaseColumns(smartElevatorMaintenanceUseCase),
    property.attributes?.comment ?? '',
  ]);
}

function getDraftBuildingByBuildingId(
  projectDraft: NewProjectData & ProjectDraftCompleted,
  buildingId: string,
): ProjectBuilding | null {
  const building = projectDraft.buildings.find((building) => Number(building.buildingId) === Number(buildingId));
  if (building) {
    return building;
  }
  return null;
}

function addPropertyServicesInfo(
  worksheet: ExcelJS.Worksheet,
  details: ProjectObjectDetails[],
  projectDraft: NewProjectData & ProjectDraftCompleted,
): void {
  worksheet.addRow(HEADER_ROW);
  worksheet.addRow(HEADER_ROW_2);

  for (const property of details.filter((item) => item.buildingType === TreeNodeClassification.Liegenschaft)) {
    const allGatewayDetails =
      property.gatewayDetails?.filter((item) => LIEGENSCHAFT_GW_REXEP.exec(item.identifier)) || [];
    let gatewayIdentifier = '';

    if (allGatewayDetails.length === 0) {
      if (property.gatewayDetails?.length) {
        gatewayIdentifier = property.gatewayDetails.map((item) => item.identifier).join(' / ');
      }
    } else {
      gatewayIdentifier = allGatewayDetails.map((item) => item.identifier).join(' / ');
    }

    // Get the property's directory
    const directory =
      details.find(
        (item) => item.objectType == 'Directory' && item.buildingReferenceId == property.buildingReferenceId,
      ) ?? null;

    // Get the draft building by buildingId
    if (property.draftBuildingId !== null) {
      const draftBuilding: ProjectBuilding | null = getDraftBuildingByBuildingId(
        projectDraft,
        property.draftBuildingId,
      );
      addStandortlisteRow(worksheet, property, null, gatewayIdentifier, draftBuilding, directory);
    }

    for (const subProperty of details.filter(
      (item) =>
        item.buildingType === TreeNodeClassification.Gebaude &&
        item.buildingReferenceId === property.buildingReferenceId,
    )) {
      // There should only be one gateway for each Gebäude
      let subGatewayIdentifier = '';

      if (subProperty.gatewayDetails?.length) {
        subGatewayIdentifier = subProperty.gatewayDetails.map((item) => item.identifier).join(' / ');
      }

      addStandortlisteRow(worksheet, subProperty, property, subGatewayIdentifier, null, null);
    }
  }
}

function addBicab2Rows(worksheet: ExcelJS.Worksheet, details: ProjectObjectDetails[]): void {
  worksheet.addRow(BICAB_HEADER_ROW);
  worksheet.addRow(BICAB_HEADER_ROW_2);

  details.forEach((property) => {
    worksheet.addRow([
      getExcelBuildingType(property.buildingType ?? ''),
      property.buildingReferenceId,
      property.itemName,
      getLocation(property),
      'BICAB 2.0',
      '300/50',
      0,
      0,
      'VF-Station	',
      'Standard Advanced',
      'Absprache Techniker-Kunde	',
      'CPE im Schrank',
    ]);
  });
}

function getStyleOptions(details: ProjectObjectDetails[], projectType: EnterpriseProjectType): StyleOptions {
  const enabledUseCases = new Set<EnterpriseProjectObjectUseCase>();
  const enabledProducts = new Set<EnterpriseProjectObjectProduct>();

  let commentsEnabled = false;

  for (const item of details) {
    for (const useCase of item.useCaseList) {
      if (!enabledUseCases.has(useCase.useCaseType)) {
        enabledUseCases.add(useCase.useCaseType);
      }
    }

    for (const product of item.products) {
      if (product.productType && !enabledProducts.has(product.productType)) {
        enabledProducts.add(product.productType);
      }
    }

    // Check for any comments in order to hide the column if they are absent
    if (!commentsEnabled && item.attributes?.comment) {
      commentsEnabled = true;
    }
  }

  // default heading starting position
  let headingStartRow = 10;

  if (projectType === EnterpriseProjectType.NEW_PRODUCT_ORIGINAL_USE_CASE) {
    // Additional rows are added before the heading, which shifts the heading starting position
    headingStartRow += 1;
  }

  const subHeadingRow = headingStartRow + 1;

  const dataStartRow = headingStartRow + 2;

  return { enabledUseCases, enabledProducts, headingStartRow, subHeadingRow, dataStartRow, commentsEnabled };
}

async function queryAllProjectDetailsData(
  client: ApolloClient<unknown>,
  variables: Omit<GetProjectDetailsExportQueryVariables, 'skip' | 'take'>,
): Promise<ProjectObjectDetails[]> {
  let allDetails: ProjectObjectDetails[] = [];
  let details: ApolloQueryResult<GetProjectDetailsExportQuery>;
  let skip = 0;

  do {
    details = await client.query<GetProjectDetailsExportQuery>({
      query: projectDetailExportQuery,
      fetchPolicy: 'no-cache',
      variables: { ...variables, skip, take: 500 },
    });

    allDetails = allDetails.concat(details.data.projectDetails.items);
    skip += 500;
  } while (details.data.projectDetails.count > allDetails.length);

  return allDetails;
}

async function getProjectDraftCustomerInfo(
  projectDraft: NewProjectData & ProjectDraftCompleted,
): Promise<CustomerInfo> {
  if (!projectDraft?.customerInfo) {
    return {
      customerName: null,
      customerNumber: null,
      invoiceNumber: null,
      contractTerm: null,
      createNewInvoiceNumber: false,
      referenceToMainContract: false,
      transferToMainContract: false,
      comment: null,
    };
  }

  // Get the customer info from the project draft
  const customerName = projectDraft.general.customerName;
  const customerNumber = projectDraft.general.customerNumber;
  const invoiceNumber = projectDraft.general.invoiceNumber;
  const contractTerm = projectDraft.general.contractTerm;
  const comment = projectDraft.general.comment;
  const createNewInvoiceNumber = projectDraft.general.createNewInvoiceNumber
    ? projectDraft.general.createNewInvoiceNumber
    : false;
  const referenceToMainContract = projectDraft.general.referenceToMainContract
    ? projectDraft.general.referenceToMainContract
    : false;
  const transferToMainContract = projectDraft.general.transferToMainContract
    ? projectDraft.general.transferToMainContract
    : false;
  return {
    customerName,
    customerNumber,
    invoiceNumber,
    contractTerm,
    createNewInvoiceNumber,
    referenceToMainContract,
    transferToMainContract,
    comment,
  };
}

function determineInvoiceValue(customerInfo: {
  invoiceNumber: string | undefined | null;
  createNewInvoiceNumber: boolean;
}): string {
  let invoiceNumber = customerInfo.invoiceNumber ?? '';
  // Apply conditional logic for invoiceNumber (C5)
  if (customerInfo.createNewInvoiceNumber) {
    // Checkbox is checked
    if (invoiceNumber) {
      // Invoice number is populated
      invoiceNumber = invoiceNumber + ' (Neue Nummer anlegen)';
    } else {
      // Invoice number is blank
      invoiceNumber = '(Neue Nummer anlegen)';
    }
  } else {
    // Checkbox is not checked
    if (!invoiceNumber) {
      // If no invoice number provided, keep it empty
      invoiceNumber = '';
    }
    // If invoice number is provided, keep the value as is (no change)
  }
  return invoiceNumber;
}

// Only display product names/abbreviations for which there has been a change from parent to child project
// If more than 1 product has changed, display the names comma-separated, and in the following order:
// “Main Service” (Full FE word)
// “Infrastrukturvariante” (Full FE word)
// “Heizung” (Abbreviation only, e.g. HzM, HzP)
// “Aufzug” (Abbreviation only, e.g. AzM, SAM)
// Example: Altprodukt/e: Basis, Standard, HzM, SAM
// eslint-disable-next-line complexity
function buildChangeListText(project: GetProjectQuery_project): string | null {
  if (!project.projectCopyChanges || project.type !== EnterpriseProjectType.NEW_PRODUCT_ORIGINAL_USE_CASE) {
    return null;
  }

  const changes = project.projectCopyChanges;
  const changeList: string[] = [];

  if (changes.oldMainService !== changes.newMainService && changes.oldMainService !== null) {
    changeList.push(changes.oldMainService);
  }
  if (changes.oldInfrastructure !== changes.newInfrastructure && changes.oldInfrastructure !== null) {
    changeList.push(changes.oldInfrastructure);
  }
  if (changes.oldHeating !== changes.newHeating && changes.oldHeating !== null) {
    const heatingCode =
      useCasesForProjectChangesText.get(changes.oldHeating as EnterpriseProjectObjectUseCase) ?? changes.oldHeating;
    changeList.push(heatingCode);
  }
  if (changes.oldElevator !== changes.newElevator && changes.oldElevator !== null) {
    const elevatorCode =
      useCasesForProjectChangesText.get(changes.oldElevator as EnterpriseProjectObjectUseCase) ?? changes.oldElevator;
    changeList.push(elevatorCode);
  }

  return `Altprodukt/e: ${changeList.join(', ')}`;
}

function getContractDurationComment(customerInfo: CustomerInfo): string {
  let contractDurationComment = '';
  if (customerInfo.transferToMainContract || customerInfo.referenceToMainContract) {
    contractDurationComment = `${customerInfo.comment ?? ''}`;
  }
  return contractDurationComment;
}

export async function generateStandortlist(
  project: GetProjectQuery_project,
  client: ApolloClient<unknown>,
  projectDraft: ProjectDraft,
): Promise<void> {
  const details = await queryAllProjectDetailsData(client, { projectReference: project.projectReference });
  // Get the customer info from the project draft
  const customerInfo = await getProjectDraftCustomerInfo(projectDraft);
  // Extract relevant values with fallback defaults
  const customerNumber = customerInfo.customerNumber ?? '';
  const contractTerm = formatContractTerm(customerInfo);
  // Determine the product changes
  const changeList = buildChangeListText(project);
  // Define the preamble array with adjusted invoiceNumber logic
  const preamble = [
    ['Standortliste GK4.0'],
    ['Kundenname:', '', customerInfo.customerName ?? 'NO CUSTOMER'],
    ['Kundennummer:', '', customerNumber],
    ['Rechnungskontonummer', '', determineInvoiceValue(customerInfo)],
    ['Vertragslaufzeit', '', contractTerm, getContractDurationComment(customerInfo)],
    ['Email Admin Kunde', '', project.salesContactUserName],
    ['Projekt ID', '', project.projectReference],
    [],
  ];
  // If the project is of type NEW_PRODUCT_ORIGINAL_USE_CASE then we want to add the project type and change list to the preamble
  // The type should be the second index and the changes the third index
  if (project.type === EnterpriseProjectType.NEW_PRODUCT_ORIGINAL_USE_CASE) {
    // Get the german translation of the project type from EnterpriseProjectCopyOption
    const typeLabel = EnterpriseProjectCopyOption.find((option) => option.value === project.type)?.label;
    preamble.splice(1, 0, [typeLabel ?? '']);
    preamble.splice(2, 0, [changeList ?? '']);
  } else {
    preamble.splice(1, 0, ['Neubestellung']);
  }

  const bicabPreamble = preamble.map((row) => [...row]);
  bicabPreamble[0].push('Kabel-Access für GK4.0');
  bicabPreamble[1][0] = 'Neubestellung';

  const workbook = new ExcelJS.Workbook();
  const gk40Worksheet = workbook.addWorksheet('GK4.0', {
    views: [{ showGridLines: false }],
  });

  setGk40Columns(gk40Worksheet);
  gk40Worksheet.addRows(preamble);
  addPropertyServicesInfo(gk40Worksheet, details, projectDraft);
  const styleOptions = getStyleOptions(details, project.type);

  styleGk4NullSheet(gk40Worksheet, styleOptions);
  await gk40Worksheet.protect(atob('Z2s0bnVsbCEyMDI0JA=='), defaultSpreadsheetProtectionOptions);

  const bicabDetails = keepBicabRows(details);

  if (bicabDetails.length > 0) {
    // only add bicab worksheet if there's bicab related details to add
    const bicabWorksheet = workbook.addWorksheet('BICAB 2.0 für GK4.0', {
      views: [{ showGridLines: false, zoomScale: 145 }],
    });

    setBicabColumns(bicabWorksheet);
    bicabWorksheet.addRows(bicabPreamble);
    addBicab2Rows(bicabWorksheet, bicabDetails);
    styleBicarbSheet(bicabWorksheet, styleOptions);
    await bicabWorksheet.protect(atob('Z2s0bnVsbCEyMDI0JA=='), defaultSpreadsheetProtectionOptions);
  }

  // Code adapted from https://stackoverflow.com/a/74728655 and https://github.com/exceljs/exceljs/issues/402#issuecomment-347927095
  const workbookBuffer = await workbook.xlsx.writeBuffer({ base64: true } as unknown as ExcelJS.XlsxWriteOptions);

  const downloadBlob = new Blob([workbookBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = URL.createObjectURL(downloadBlob);
  link.download = 'GK4.0_Standortliste.xlsx';
  link.click();
  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
  }, 0);
}

function keepBicabRows(details: ProjectObjectDetails[]): ProjectObjectDetails[] {
  const bicabRows: ProjectObjectDetails[] = details.filter(
    (item) =>
      item.buildingType === TreeNodeClassification.Liegenschaft &&
      item.mainService === EnterpriseProjectServiceType.GK_HUB_L_CABLE,
  );

  return bicabRows;
}
