
























import { defineComponent } from '@vue/composition-api';
import { mapGetters, mapState } from 'vuex';
import InputText from '@/features/ui/inputs/InputText.global.vue';

export default defineComponent<any, Record<string, unknown>, any, any, any>({
  components: { InputText },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState({}),
    ...mapGetters(['filteredRegisterValues']),
  },
  methods: {},
});
