























































































































import { ComponentGroup, HeatingSystem, TreeNode } from '@/util/modbus';
import { defineComponent, PropType } from '@vue/composition-api';
import { HeatingSystemCollectionWithPaginationQuery } from '@/hsc-api/queries/__generated__/HeatingSystemCollectionWithPaginationQuery';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import heatingSystemsListQuery from '@/hsc-api/queries/HeatingSystemCollectionWithPaginationQuery.gql';
import { SpotQueryWithLatestMetrics } from '../../__generated__/SpotQueryWithLatestMetrics';
import GroupsMixin from '@/features/core/components/mixins/groups';
import { HeatingSystemType } from '@/util/heating-systems';
import AdvancedViewGraphModal from '@/features/app-heating-system/views/tree-node/heating-systems-list/components/AdvancedViewGraphModal.vue';

interface TreeNodeHeatingSystem {
  treeNodeId: string;
  heatingSystems: HeatingSystem[];
}

export default defineComponent({
  components: {
    AdvancedViewGraphModal,
  },
  mixins: [GroupsMixin],
  props: {
    treeNodes: {
      type: Array as PropType<TreeNode[]>,
      required: true,
    },
    modbusMetricNames: {
      type: Array as PropType<string[]>,
      required: true,
    },
    components: {
      type: Array as PropType<ComponentGroup[]>,
      required: true,
    },
    spots: {
      type: Array as PropType<SpotQueryWithLatestMetrics['spots']['items']>,
      required: true,
    },
    expandAll: {
      type: Boolean,
      required: false,
    },
    heatingSystems: {
      type: Array as PropType<HeatingSystem[]>,
      required: true,
    },
    selectedHeatingSystemType: {
      type: String as PropType<HeatingSystemType | null>,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selectedSpotId: null as string | null,
      selectedHeatingSystem: null as HeatingSystem | null,
      skip: 0,
      take: 500,
      nodes: [] as TreeNode[],
      treeNodeHeatingSystems: [] as TreeNodeHeatingSystem[],
      showContextMenu: false,
      contextMenuStyle: {
        top: '0px',
        left: '0px',
      },
      contextMenuHeatingSystem: null as HeatingSystem | null,
    };
  },
  computed: {
    spotId(): string {
      const spotIds = this.selectedHeatingSystem?.heatingSystemMeasurementGroups[0].heatingSystemMeasurements.map(
        (m) => m.spotId,
      );
      return Array.from(new Set(spotIds))[0];
    },
    filteredTreeNodes(): TreeNode[] {
      const treeNodes = this.treeNodes.map((item) => ({
        ...item,
        isOpen: true,
      }));

      if (this.advancedViewSearchQuery) {
        const searchQuery = String(this.advancedViewSearchQuery).toLowerCase();
        return treeNodes.filter(
          (treeNode) =>
            treeNode.name.toLowerCase().includes(searchQuery) || this.getHeatingSystems(treeNode.id).length > 0,
        );
      }

      return treeNodes;
    },
    treeNodeIds(): string[] {
      return this.filteredTreeNodes.map((item) => item.id);
    },
    advancedViewSearchQuery(): string | null {
      return this.$store.getters.advancedViewSearchQuery;
    },
  },
  watch: {
    expandAll(val: boolean) {
      this.filteredTreeNodes.forEach((node) => {
        node.isOpen = val;
      });
    },
    advancedViewSearchQuery() {
      this.nodes = this.filteredTreeNodes;
    },
    selectedHeatingSystemType() {
      this.nodes = this.filteredTreeNodes;
    },
  },
  async mounted() {
    this.nodes = this.filteredTreeNodes;

    for (const id of this.treeNodeIds) {
      const items = await this.fetchHeatingSystems(id);
      this.treeNodeHeatingSystems.push({
        treeNodeId: id,
        heatingSystems: items.length > 0 ? items : [],
      });
    }
  },
  methods: {
    async fetchHeatingSystems(id: string): Promise<HeatingSystem[]> {
      try {
        this.isLoadingHeatingSystems = true;
        const { data } = await this.$apollo.query<HeatingSystemCollectionWithPaginationQuery>({
          client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
          query: heatingSystemsListQuery,
          variables: {
            customerIdOrSiteId: id,
            skip: this.skip,
            take: this.take,
          },
        });

        return data.heatingSystemsList.items.filter((item) => item.heatingSystemMeasurementGroups.length > 0);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return [];
      } finally {
        this.isLoadingHeatingSystems = false;
      }
    },
    getHeatingSystems(treeNodeId: string): HeatingSystem[] {
      let heatingSystems =
        this.treeNodeHeatingSystems.find((item) => item.treeNodeId === treeNodeId)?.heatingSystems ?? [];

      if (this.advancedViewSearchQuery) {
        const searchQuery = String(this.advancedViewSearchQuery).toLowerCase();
        heatingSystems = heatingSystems.filter((hs) => hs.name.toLowerCase().includes(searchQuery));
      }

      if (this.selectedHeatingSystemType === HeatingSystemType.Kessel) {
        heatingSystems = heatingSystems.filter((hs) =>
          hs.heatingSystemMeasurementGroups.some((group) => group.systemComponentTypeName === 'BOILER'),
        );
      }

      if (this.selectedHeatingSystemType === HeatingSystemType.Fernwärme) {
        heatingSystems = heatingSystems.filter((hs) =>
          hs.heatingSystemMeasurementGroups.some((group) => group.systemComponentTypeName === 'TELE_HEATING'),
        );
      }

      return heatingSystems;
    },
    getMetricValue(hs: HeatingSystem, metricName: string, groupName: string): string {
      let value = '-';

      const spotId = hs.heatingSystemMeasurementGroups.find((group) => group.groupName === groupName)
        ?.heatingSystemMeasurements[0]?.spotId;
      if (spotId) {
        const spot = this.spots.find((spot) => spot.id === spotId);
        if (spot) {
          spot.metrics.forEach((metric) => {
            if (metric.__typename === 'ContinuousMetric' && metric.name === metricName) {
              value = String(metric.latest.value);
            }
            if (spot.role === 'HEATING_CONTROL') {
              // get actual metric name from HS
              const measurements = hs.heatingSystemMeasurementGroups.find(
                (group) => group.groupName === groupName,
              )?.heatingSystemMeasurements;
              let actualMetricName: string | undefined;
              if (measurements && metricName === 'temperature1') {
                actualMetricName = measurements.find(
                  (item) => item.metricLabel.includes('Vorlauf') || item.metricLabel.includes('Warmwasser'),
                )?.metricName;
              } else if (measurements && metricName === 'temperature2') {
                actualMetricName = measurements.find(
                  (item) => item.metricLabel.includes('Rücklauf') || item.metricLabel.includes('Zirkulation'),
                )?.metricName;
              }
              if (metric.__typename === 'ContinuousMetric' && metric.name === actualMetricName) {
                value = String(metric.latest.value);
              }
            }
          });
        }
      }

      return value;
    },
    getSpotName(heatingSystem: HeatingSystem): string {
      const spotIds = heatingSystem.heatingSystemMeasurementGroups.flatMap((group) =>
        group.heatingSystemMeasurements.flatMap(({ spotId }) => spotId),
      );

      if (spotIds.length > 0) {
        return this.spots.find((spot) => spot.id === spotIds[0])?.name || '';
      }

      return '';
    },
    isHeatingControl(heatingSystem: HeatingSystem): boolean {
      return heatingSystem.heatingSystemMeasurementGroups.find(
        (group) => group.systemComponentTypeName === 'HEATING_CONTROL',
      )
        ? true
        : false;
    },
    openGraphModal(hs: HeatingSystem) {
      this.selectedHeatingSystem = hs;
      this.selectedSpotId = this.spotId;

      this.$store.commit('setSelectedGraphObject', hs);
      this.$store.commit('showAdvancedViewGraph', true);
    },
    goToRegisters(hs: HeatingSystem) {
      this.$router.push({ name: 'AppHeatingSystem/EditRegisters', params: { treeNodeId: hs.siteId } });
    },
    handleContextMenu(event: MouseEvent, hs: HeatingSystem) {
      event.preventDefault();
      this.showContextMenu = true;
      this.contextMenuHeatingSystem = hs;
      this.contextMenuStyle = {
        top: `${event.clientY}px`,
        left: `${event.clientX}px`,
      };

      // Add click outside listener
      document.addEventListener('click', this.hideContextMenu);
    },
    hideContextMenu() {
      this.showContextMenu = false;
      this.contextMenuHeatingSystem = null;
      document.removeEventListener('click', this.hideContextMenu);
    },
    handleContextMenuAction() {
      if (this.contextMenuHeatingSystem) {
        this.goToRegisters(this.contextMenuHeatingSystem);
      }
      this.hideContextMenu();
    },
    showRow(hs: HeatingSystem): boolean {
      if (this.selectedHeatingSystemType === HeatingSystemType.Kessel) {
        return hs.heatingSystemMeasurementGroups.some((group) => group.systemComponentTypeName === 'BOILER');
      }

      if (this.selectedHeatingSystemType === HeatingSystemType.Fernwärme) {
        return hs.heatingSystemMeasurementGroups.some((group) => group.systemComponentTypeName === 'TELE_HEATING');
      }

      return true;
    },
    hasHeatingSystems(treeNodeId: string): boolean {
      return this.getHeatingSystems(treeNodeId).length > 0;
    },
  },
});
