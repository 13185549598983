/**
 * Modbus helper functions.
 */

import { CoreTreeNodeBarControlPathQuery } from '@/features/core/components/tree-node-bar-control/__generated__/CoreTreeNodeBarControlPathQuery';
import { HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items } from '@/hsc-api/queries/__generated__/HeatingSystemCollectionWithPaginationQuery';

export type HeatingSystem = HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items;

export type TreeNode = CoreTreeNodeBarControlPathQuery['treeNodes']['items'][number]['children']['items'][number] & {
  isOpen?: boolean;
};

export interface DecoderRegister {
  registerNumber: string;
  registerName: string;
  registerFormat: string;
  metricName: string;
  unit: string;
  dataType: string;
  offset: number;
  scaleFactor: number;
  functionCode: number;
  intervalSeconds: number;
  tableData: string;
  standardMetric: boolean;
}

export interface Decoder {
  deviceManufacturer: string;
  deviceModel: string;
  deviceRole: string;
  deviceVersion: string;
  registers: DecoderRegister[];
}

export interface ComponentGroup {
  groupName: string;
  type: string;
  spotId: string;
}

export function getComponentsGroupNames(heatingSystems: HeatingSystem[]): ComponentGroup[] {
  const components: ComponentGroup[] = [];

  heatingSystems.forEach((hs) => {
    hs.heatingSystemMeasurementGroups.forEach((group) => {
      group.heatingSystemMeasurements.forEach((measurement) => {
        const exists = components.find((item) => item.groupName === group.groupName);
        if (!exists) {
          components.push({
            groupName: group.groupName,
            type: group.systemComponentTypeName,
            spotId: measurement.spotId,
          });
        }
      });
    });
  });

  return components.sort((a, b) => {
    // ensure HEATING_CONTROL items are at the bottom
    if (a.type === 'HEATING_CONTROL') return 1;
    if (b.type === 'HEATING_CONTROL') return -1;

    return a.groupName.localeCompare(b.groupName);
  });
}
