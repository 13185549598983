

















































































































import { defineComponent } from '@vue/composition-api';
import Panel from '@/features/ui/panel/Panel.global.vue';
import PropertyList from '@/features/app-enterprise-project/views/enterprise-project-create/components/step2/PropertyList.vue';
import ProjectBuildingInformationSelect from '@/features/app-enterprise-project/views/enterprise-project-create/components/building-information/ProjectBuildingInformationSelect.vue';
import { mapGetters, mapState } from 'vuex';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';
import FormField from '@/features/ui/form/FormField.global.vue';
import Form from '@/features/ui/form/Form.global.vue';
import FormMessage from '@/features/ui/form/FormMessage.global.vue';

interface EnterpriseBuildingInformationMethods {
  [key: string]: (...args: any[]) => void;
}

export default defineComponent<any, Record<string, unknown>, any, any, EnterpriseBuildingInformationMethods>({
  components: {
    Form,
    FormField,
    WizardButton,
    ProjectBuildingInformationSelect,
    PropertyList,
    Panel,
    FormMessage,
  },
  props: {},
  data() {
    return {
      buildingFormData: null,
    };
  },
  computed: {
    ...mapGetters([
      'buildingInformationFormValid',
      'showPropertyInformationCopySelection',
      'allMainBuildingInformationCompleted',
      'buildingListForStep1InformationCopy',
    ]),
    ...mapState({
      newProjectData: (state: any) => state.EnterpriseProjectCreateModule.newProjectData,
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      activeStep2BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep2BuildingId,
      buildingsCreated: (state: any) => state.EnterpriseProjectCreateModule.buildingsCreated,
      editableBuildings: (state: any) => state.EnterpriseProjectCreateModule.editableBuildings,
      saving: (state: any) => state.EnterpriseProjectCreateModule.saving,
      activeBuilding: (state: any) => {
        if (
          state.EnterpriseProjectCreateModule.newProjectData.buildings &&
          state.EnterpriseProjectCreateModule.newProjectData.buildings?.length > 0
        ) {
          // Find active building by buildingId
          return state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
            (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep2BuildingId,
          );
        }
      },
      mainBuildings(state: any) {
        return state.EnterpriseProjectCreateModule.newProjectData.buildings.filter(
          (building: ProjectBuilding) => building.liegenshaft,
        );
      },
      firstName: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
          (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep2BuildingId,
        )?.liegenshaftInformation?.firstName || '',
      surname: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
          (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep2BuildingId,
        )?.liegenshaftInformation?.surname || '',
      title: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
          (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep2BuildingId,
        )?.liegenshaftInformation?.title || '',
      phoneNumber: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
          (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep2BuildingId,
        )?.liegenshaftInformation?.phoneNumber || '',
      email: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
          (building: any) => building.buildingId === state.EnterpriseProjectCreateModule.activeStep2BuildingId,
        )?.liegenshaftInformation?.email || '',
    }),
    savingDisabled() {
      if (this.mainBuildings.length === 1) {
        // If only one building, check if the form is valid
        return !this.buildingInformationFormValid;
      }
      return !this.allMainBuildingInformationCompleted || !this.buildingInformationFormValid;
    },
    formDisabled() {
      return !!(
        this.activeBuilding &&
        this.buildingsCreated &&
        !this.editableBuildings.has(`${this.activeBuilding.buildingId}`)
      );
    },
  },
  watch: {
    activeBuilding: {
      handler() {
        // Access the submit method using the ref
        const formRef = this.$refs.buildingInformationForm;
        if (formRef && formRef.reset) {
          formRef.reset();
        }
        this.updateStoreWithActiveBuilding();
      },
      immediate: true,
    },
  },
  mounted() {
    this.updateStoreWithActiveBuilding();
  },
  methods: {
    async updateBuildingInformation(formData: any) {
      this.$store.commit('setShowPropertyInformationCopySelection', false);
      await this.$store.dispatch('saveBuildingInformation', {
        buildingInformation: formData,
        apolloClient: this.$apollo,
        continueToNextBuilding: false,
      });
      return;
    },
    // Form
    validateEmail(email: string) {
      // Regular expression pattern for email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Test the email against the pattern
      return emailPattern.test(email);
    },
    validateForm(formData): boolean {
      if (!formData) {
        return false;
      }
      if (
        this.validateEmail(formData.email) &&
        formData.phoneNumber &&
        formData.firstName &&
        formData.surname &&
        formData.title
      ) {
        return true;
      }
      return false;
    },
    goToStep(step: string) {
      this.triggerSubmit();
      this.$store.dispatch('setUpdateTab', step);
      this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: false });
    },
    updateStore(formData: any) {
      this.$store.commit('setBuildingInformationFormValid', this.validateForm(formData));
    },
    async onFieldBlur(formData: any) {
      const formValid = this.validateForm(formData);
      this.$store.commit('setBuildingInformationFormValid', formValid);

      if (formValid) {
        this.updateBuildingInformation(formData);
      }
    },
    // Method to expose the submit function to the parent
    triggerSubmit() {
      // Access the submit method using the ref
      const formRef = this.$refs.buildingInformationForm;
      if (formRef && formRef.submit) {
        formRef.submit();
      }
    },
    setShowPropertyInformationCopySelection() {
      this.$store.commit('setShowPropertyInformationCopySelection', !this.showPropertyInformationCopySelection);
    },
    updateStoreWithActiveBuilding() {
      if (this.activeBuilding?.liegenshaftInformation) {
        this.updateStore({
          firstName: this.activeBuilding.liegenshaftInformation.firstName,
          surname: this.activeBuilding.liegenshaftInformation.surname,
          title: this.activeBuilding.liegenshaftInformation.title,
          phoneNumber: this.activeBuilding.liegenshaftInformation.phoneNumber,
          email: this.activeBuilding.liegenshaftInformation.email,
        });
      }
    },
  },
});
