import { render, staticRenderFns } from "./PropertyBuildingAssociation.vue?vue&type=template&id=6f469434&scoped=true&"
import script from "./PropertyBuildingAssociation.vue?vue&type=script&lang=ts&"
export * from "./PropertyBuildingAssociation.vue?vue&type=script&lang=ts&"
import style0 from "./PropertyBuildingAssociation.vue?vue&type=style&index=0&id=6f469434&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f469434",
  null
  
)

export default component.exports