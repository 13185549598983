


























































import { EneriqTypesMixin } from '@/features/app-heating-system/components/mixins/measurements/eneriq-types-mixin';
import GroupsMixin from '@/features/core/components/mixins/groups';
import { defineComponent } from '@vue/composition-api';
import {
  HeatingSystemCollectionWithPaginationQuery,
  HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items,
} from '@/hsc-api/queries/__generated__/HeatingSystemCollectionWithPaginationQuery';
import CustomerSelection from './CustomerSelection.vue';
import { AppCustomerViewQuery_customers_items } from '@/features/app-customer/views/customer/__generated__/AppCustomerViewQuery';
import {
  CoreTreeNodeBarControlPathQuery,
  CoreTreeNodeBarControlPathQueryVariables,
} from '@/features/core/components/tree-node-bar-control/__generated__/CoreTreeNodeBarControlPathQuery';
import customerTreeNodesQuery from '@/features/core/components/tree-node-bar-control/path.gql';
import { fernwärmeTypes, HeatingSystemType, kesselTypes } from '@/util/heating-systems';
import { ADMIN_APP_ENDPOINT } from '@/env';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import { keycloak } from '@/keycloak';
import { ComponentGroup, getComponentsGroupNames, Decoder } from '@/util/modbus';
import { SpotQueryWithLatestMetrics } from '../__generated__/SpotQueryWithLatestMetrics';
import AdvancedViewTableData from '@/features/app-heating-system/views/tree-node/heating-systems-list/components/AdvancedViewTableData.vue';
import heatingSystemsListQuery from '@/hsc-api/queries/HeatingSystemCollectionWithPaginationQuery.gql';
import metricsQuery from '@/features/app-heating-system/views/tree-node/spot-with-latest-metrics.gql';

export type HeatingSystem = HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items & {
  health?: string;
};

type Customer = AppCustomerViewQuery_customers_items;

export default defineComponent({
  components: {
    CustomerSelection,
    AdvancedViewTableData,
  },
  mixins: [EneriqTypesMixin, GroupsMixin],
  data() {
    return {
      isLoadingHeatingSystems: false,
      isLoadingMetrics: false,
      isLoadingSummaries: false,
      isLoadingTreeNodes: false,
      isLoadingDecoders: false,
      customerTreeNodes: [] as CoreTreeNodeBarControlPathQuery['treeNodes']['items'],
      filteredTreeNodes: [] as CoreTreeNodeBarControlPathQuery['treeNodes']['items'],
      expandedTreeNodes: [] as string[],
      selectedCustomer: null as Customer | null,
      selectedHeatingSystemType: null as HeatingSystemType | null,
      skip: 0,
      take: 500,
      expandAll: true,
      heatingSystems: [] as HeatingSystem[],
      spots: [] as SpotQueryWithLatestMetrics['spots']['items'],
      modbusMetricNames: [] as string[],
      searchQuery: null as string | null,
    };
  },
  computed: {
    isLoading(): boolean {
      return this.isLoadingTreeNodes || this.isLoadingHeatingSystems || this.isLoadingMetrics;
    },
    heatingSystemTypes(): HeatingSystemType[] {
      return this.$store.getters.advancedViewFilters;
    },
    getSpotIds(): string[] {
      const uniqueSpotIds = new Set<string>();
      this.heatingSystems.forEach((hs) => {
        hs.heatingSystemMeasurementGroups?.forEach((group) => {
          group.heatingSystemMeasurements?.forEach((measurement) => {
            uniqueSpotIds.add(measurement.spotId);
          });
        });
      });

      return Array.from(uniqueSpotIds);
    },
    filteredHeatingSystems(): HeatingSystem[] {
      if (this.selectedHeatingSystemType === HeatingSystemType.Kessel) {
        return this.heatingSystems.filter((hs) =>
          hs.heatingSystemMeasurementGroups.some((group) => group.systemComponentTypeName === 'BOILER'),
        );
      } else if (this.selectedHeatingSystemType === HeatingSystemType.Fernwärme) {
        return this.heatingSystems.filter((hs) =>
          hs.heatingSystemMeasurementGroups.some((group) => group.systemComponentTypeName === 'TELE_HEATING'),
        );
      }
      return this.heatingSystems;
    },
    getComponets(): ComponentGroup[] {
      let components = getComponentsGroupNames(this.filteredHeatingSystems);

      if (this.selectedHeatingSystemType && this.selectedHeatingSystemType === HeatingSystemType.Kessel) {
        components = components.filter((item) => kesselTypes().includes(item.type));
      }

      if (this.selectedHeatingSystemType && this.selectedHeatingSystemType === HeatingSystemType.Fernwärme) {
        components = components.filter((item) => fernwärmeTypes().includes(item.type));
      }

      return components;
    },
  },
  watch: {
    searchQuery(val: string | null) {
      this.$store.commit('setAdvancedViewSearchQuery', val);
    },
    heatingSystemTypes(types: HeatingSystemType[]) {
      if (types.length > 0) {
        // select first type by default
        this.selectedHeatingSystemType = types[0];
      }
    },
  },
  beforeDestroy() {
    // close heating system advanced view if user navigates to other pages.
    // this also restores/unhide the tree node sidebar.
    this.$store.commit('showAdvancedView', false);
  },
  methods: {
    onSelectHeatingSystemType(val: HeatingSystemType | null) {
      this.selectedHeatingSystemType = val;
    },
    async fetchTreeNodesByCustomer(id: string) {
      try {
        this.isLoadingTreeNodes = true;

        const { data } = await this.$apollo.query<
          CoreTreeNodeBarControlPathQuery,
          CoreTreeNodeBarControlPathQueryVariables
        >({ query: customerTreeNodesQuery, variables: { id } });

        this.customerTreeNodes = data.treeNodes.items;
        this.filteredTreeNodes = this.customerTreeNodes;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.isLoadingTreeNodes = false;
      }
    },
    async fetchHeatingSystems(id: string): Promise<void> {
      try {
        this.isLoadingHeatingSystems = true;
        const { data } = await this.$apollo.query<HeatingSystemCollectionWithPaginationQuery>({
          client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
          query: heatingSystemsListQuery,
          variables: {
            customerIdOrSiteId: id,
            skip: this.skip,
            take: this.take,
          },
        });

        this.heatingSystems = data.heatingSystemsList.items.filter(
          (item) => item.heatingSystemMeasurementGroups.length > 0,
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.isLoadingHeatingSystems = false;
      }
    },
    async fetchMetrics(spotIds: string[]): Promise<void> {
      try {
        this.isLoadingMetrics = true;
        const { data } = await this.$apollo.query<SpotQueryWithLatestMetrics>({
          client: APOLLO_CLIENT.PORTAL_CLIENT,
          query: metricsQuery,
          variables: {
            spotIds,
            skip: 0,
            take: 500,
          },
        });
        this.spots = data.spots.items;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.isLoadingMetrics = false;
      }
    },
    async fetchDecoders(manufacturer: string, model: string, role: string): Promise<Decoder[] | null> {
      try {
        this.isLoadingDecoders = true;
        let baseUrl = 'https://prod.backend.gk4null.de/';
        if (ADMIN_APP_ENDPOINT) {
          baseUrl = new URL('', ADMIN_APP_ENDPOINT).toString();
        }
        const queryString = new URLSearchParams({
          manufacturer,
          model,
          role,
        }).toString();
        const res = await fetch(`${baseUrl}modbus/api/decoders?${queryString}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${keycloak?.token ?? ''}`,
          },
        });
        return res.ok ? await res.json() : null;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return null;
      } finally {
        this.isLoadingDecoders = false;
      }
    },
    async getModbusMetricNames(): Promise<void> {
      const metricNames: string[] = [];
      try {
        this.isLoadingDecoders = true;

        // get modbus spots (we need manufaturer, model, name from each)
        const modbusSpots = this.spots.filter((spot) => spot.role === 'HEATING_CONTROL');

        for (const spot of modbusSpots) {
          const deviceName = spot.name.toLocaleLowerCase();
          const deviceManufacturer = String(spot.deviceMounts.first.manufacturer).toLocaleLowerCase();
          const deviceRole = spot.role;
          // get decoders/registers for each spot
          const decoders = await this.fetchDecoders(deviceManufacturer, deviceName, deviceRole);
          decoders?.forEach((decoder) => {
            decoder.registers.forEach((register) => {
              const exists = metricNames.find((metricName) => metricName === register.metricName);
              if (!exists && register.tableData === 'graph') {
                metricNames.push(register.registerName);
              }
            });
          });
        }

        this.modbusMetricNames = metricNames;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.isLoadingDecoders = false;
      }
    },
    async onUpdateCustomer(customer: Customer): Promise<void> {
      this.selectedCustomer = customer;

      this.fetchTreeNodesByCustomer(customer.id);

      await this.fetchHeatingSystems(customer.id);
      this.setHeatingSystemFilters();
      await this.fetchMetrics(this.getSpotIds);
      await this.getModbusMetricNames();
    },
    setHeatingSystemFilters(): void {
      const types = new Set();
      this.heatingSystems.forEach((hs) => {
        hs.heatingSystemMeasurementGroups.forEach((group) => {
          if (kesselTypes().includes(group.systemComponentTypeName)) {
            types.add(HeatingSystemType.Kessel);
          }
          if (fernwärmeTypes().includes(group.systemComponentTypeName)) {
            types.add(HeatingSystemType.Fernwärme);
          }
        });
      });
      this.$store.commit('setAdvancedViewFilters', Array.from(types));
    },
    openMetricsEditModal(): void {
      this.$store.commit('showEditMetricsModal', true);
    },
  },
});
