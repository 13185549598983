
























































import { defineComponent } from '@vue/composition-api';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import { mapActions, mapState } from 'vuex';
import InputCheckbox from '@/features/ui/inputs/InputCheckbox.global.vue';
import InputText from '@/features/ui/inputs/InputText.global.vue';

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: { InputText, InputCheckbox, WizardButton },
  data() {
    return {
      saving: false,
      modalOpen: false,
      searchValue: '',
      filteredHeatingSystems: [],
    };
  },
  computed: {
    ...mapState({
      heatingSystems: (state: any) => state.EditHeatingSystemRegistersModule.heatingSystems,
    }),
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    openModal() {
      this.modalOpen = true;
      this.filteredHeatingSystems = this.heatingSystems.map((heatingSystem: any) => ({
        id: heatingSystem.id,
        name: heatingSystem.name,
        checked: false,
      }));
    },
    selectAllHeatingSystems() {
      this.filteredHeatingSystems.forEach((heatingSystem: any) => {
        heatingSystem.checked = true;
      });
    },
    deselectAllHeatingSystems() {
      this.filteredHeatingSystems.forEach((heatingSystem: any) => {
        heatingSystem.checked = false;
      });
    },
  },
});
