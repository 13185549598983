













































































import { defineComponent, ref } from '@vue/composition-api';
import GroupsMixin from '@/features/core/components/mixins/groups';
import Panel from '@/features/ui/panel/Panel.global.vue';
import Cell from '@/features/ui/layout/Cell.global.vue';
import Flex from '@/features/ui/layout/Flex.global.vue';
import TableSkeleton from '@/features/ui/table/TableSkeleton.global.vue';
import InputSelect from '@/features/ui/inputs/InputSelect.global.vue';
import TextInput from '@/features/ui/inputs/InputText.global.vue';
import ControlTypeList from '@/features/app-heating-system/views/control-values/components/ControlModelList.vue';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import HeatingSystemRegisterList from '@/features/app-heating-system/views/control-values/components/HeatingSystemRegisterList.vue';
import ClusterList from '@/features/app-heating-system/views/control-values/components/ClusterList.vue';
import RegisterValues from '@/features/app-heating-system/views/control-values/components/RegisterValues.vue';
import RegisterValueCopyModal from '@/features/app-heating-system/views/control-values/components/RegisterValueCopyModal.vue';
import query from '@/hsc-api/queries/HeatingSystemCollectionWithPaginationQuery.gql';
import deviceMountModelsQuery from '@/features/app-heating-system/views/control-values/graphql/spotsWithDeviceMounts.gql';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import {
  HeatingSystemCollectionWithPaginationQuery,
  HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items,
  HeatingSystemCollectionWithPaginationQueryVariables,
} from '@/hsc-api/queries/__generated__/HeatingSystemCollectionWithPaginationQuery';
import { ApolloQueryResult } from 'apollo-client';
import {
  SpotsWithDeviceMountsVariables,
  SpotsWithDeviceMounts_spots_items_deviceMounts_items,
} from '@/features/app-heating-system/views/control-values/graphql/__generated__/SpotsWithDeviceMounts';
import {
  ControlModel,
  FilteringStatus,
} from '@/features/core/store/page-modules/heating-system-control-values/edit-registers';
import { heatingSystemStatus } from '@/features/app-heating-system/views/control-values/constants';

export default defineComponent<any, Record<string, unknown>, any, any>({
  name: 'EditRegistersView',
  components: {
    RegisterValueCopyModal,
    ControlTypeList,
    ClusterList,
    WizardButton,
    Panel,
    Cell,
    Flex,
    TableSkeleton,
    InputSelect,
    TextInput,
    HeatingSystemRegisterList,
    RegisterValues,
  },
  mixins: [GroupsMixin],
  props: {
    treeNodeId: {
      type: String,
      required: true,
    },
  },
  apollo: {
    heatingSystemsList: {
      query,
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
      fetchPolicy: 'no-cache',
      variables(): HeatingSystemCollectionWithPaginationQueryVariables {
        return {
          customerIdOrSiteId: this.treeNodeId,
          skip: 0,
          // Assume that there are not more than a 1000 heating systems in one building
          take: 1000,
        };
      },
      async result({ data }: ApolloQueryResult<HeatingSystemCollectionWithPaginationQuery>): Promise<void> {
        // Set the variable in the store
        this.$store.commit('setHeatingSystems', data.heatingSystemsList.items);
      },
    },
    heatingControlData: {
      query: deviceMountModelsQuery,
      variables(): SpotsWithDeviceMountsVariables {
        return {
          spotIds: this.heatingControlSpotIds,
        };
      },
      skip() {
        return this.heatingControlSpotIds.length === 0;
      },
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
      fetchPolicy: 'no-cache',
      async result({ data }: ApolloQueryResult<any>): Promise<void> {
        const controlModels: ControlModel[] = [];

        // Get heating systems from store
        const heatingSystems = this.$store.state.heatingSystems;

        // For each spot in the returned data
        data.items.forEach((spot: any) => {
          // Get the modbusModel if it exists in deviceMounts
          const deviceMount = spot.deviceMounts.items[0] as SpotsWithDeviceMounts_spots_items_deviceMounts_items;
          if (!deviceMount?.modbusModel) return;

          // Find the heating system that contains this spot id
          const heatingSystem = heatingSystems.find((system: any) =>
            system.heatingSystemMeasurementGroups.some((group: any) =>
              group.heatingSystemMeasurements.some((measurement: any) => measurement.spotId === spot.id),
            ),
          );

          if (heatingSystem && deviceMount.serial) {
            controlModels.push({
              name: deviceMount.modbusModel,
              heatingSystemId: heatingSystem.id,
              deviceSerialNumber: deviceMount.serial,
            });
          }
        });

        this.$store.commit('setControlModels', controlModels);
      },
    },
  },
  data() {
    return {
      search: '',
      statusFilter: '',
      heatingSystemStatus: heatingSystemStatus,
    };
  },
  computed: {
    heatingControlSpotIds(): string[] {
      if (!this.heatingSystemsList?.items) return [];

      return this.heatingSystemsList.items
        .flatMap((system: HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items) =>
          system.heatingSystemMeasurementGroups
            .filter((group) => group.systemComponentTypeName === 'HEATING_CONTROL')
            .flatMap((group) => group.heatingSystemMeasurements.map((measurement) => measurement.spotId)),
        )
        .filter((spotId: string): spotId is string => !!spotId);
    },
    loading() {
      return false;
    },
  },
  watch: {},
  mounted() {
    this.$store.dispatch('loadDevices');
    this.$store.dispatch('loadClusters');
  },
  methods: {
    onSearchUpdate(value: string) {
      if (typeof value === 'string' && value) {
        this.$store.commit('setHeatingSystemSearchTerm', value);
      }
      if (value === '') {
        this.$store.commit('setHeatingSystemSearchTerm', '');
      }
    },
    onStatusFilterUpdate(value: FilteringStatus) {
      this.statusFilter = value;
      this.$store.commit('setHeatingSystemFilteringStatus', value);
    },
    saveRegisterValues() {
      this.$store.dispatch('saveRegisterValues');
    },
  },
});
