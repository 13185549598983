










































import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { defineComponent, ref } from '@vue/composition-api';
import GroupsMixin from '@/features/core/components/mixins/groups';
import Flex from '@/features/ui/layout/Flex.global.vue';
import Cell from '@/features/ui/layout/Cell.global.vue';
import Panel from '@/features/ui/panel/Panel.global.vue';
import Pagination from '@/features/ui/pagination/Pagination.global.vue';
import getProjectStandortlisteDownloads from '../graphql/getProjectStandortlisteDownloads.gql';
import Datepicker from '@/components/datepicker/Datepicker.global.vue';
import {
  GetProjectStandortlisteDownloads,
  GetProjectStandortlisteDownloadsVariables,
} from '@/features/app-enterprise-project/views/enterprise-project-audit-logs/graphql/__generated__/GetProjectStandortlisteDownloads';
import ProjectAuditLogsStandortlisteTable from '@/features/app-enterprise-project/views/enterprise-project-audit-logs/components/ProjectAuditLogsStandortlisteTable.vue';
import { mapActions } from 'vuex';
import {
  getStartOfDayTimestamp,
  getEndOfDayTimestamp,
  isDateRangeInvalid,
} from '@/features/app-enterprise-project/views/enterprise-project-audit-logs/helpers/date-time-utils';

export default defineComponent<Record<string, unknown>, any, any>({
  name: 'ProjectAuditLogsView',
  components: {
    Flex,
    Cell,
    Panel,
    Pagination,
    Datepicker,
    ProjectAuditLogsStandortlisteTable,
  },
  apollo: {
    projectAuditLogs: {
      query: getProjectStandortlisteDownloads,
      fetchPolicy: 'network-only',
      variables() {
        return {
          projectId: this.project?.id,
          fromTimestamp: getStartOfDayTimestamp(this.fromDate),
          untilTimestamp: getEndOfDayTimestamp(this.untilDate),
          skip: this.skip,
          take: this.take,
          eventTypes: ['DOWNLOAD_STANDORTLISTE'],
        } as GetProjectStandortlisteDownloadsVariables;
      },
      result({ data }: { data: GetProjectStandortlisteDownloads }) {
        // Assign projectReference to all items
        this.projectAuditLogsForTable = data.projectAuditLogs.items.map((item) => ({
          ...item,
          projectReference: this.projectReference,
          username: item.user?.name,
        }));
      },
      skip() {
        // Skip loading until the project ID is available
        return !this.project?.id || !this.projectDetails?.items.length || !this.fromDate || !this.untilDate;
      },
    },
  },
  mixins: [PaginationQueryStringMixin, GroupsMixin],
  props: {
    projectReference: {
      type: String,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      projectList: undefined,
      customerId: undefined,
      projectAuditLogsForTable: [],
      fromDate: null,
      untilDate: null,
    };
  },
  computed: {
    loading(): boolean {
      return this.$apollo.loading;
    },
    take(): number {
      return 12;
    },
    totalPages(): number {
      const pageCount = Math.ceil((this.projectDraftAuditLogs?.count ?? 0) / this.take);

      return pageCount;
    },
    skip(): number {
      return (this.currentPage - 1) * this.take;
    },
  },
  mounted() {
    this.currentPage = 1;
    const now = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(now.getMonth() - 1); // Set one month ago
    this.fromDate = oneMonthAgo;
    this.untilDate = now;
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    onPaginationUpdate(pageNumber: number): void {
      this.currentPage = pageNumber;
    },
    changeBuildingValue(type: string, value: string): void {
      if (type === 'building') {
        this.selectedBuilding = value;
      }
    },
    async onFromDateChange(update: any): Promise<void> {
      await this.validateAndResetFromDate(update);
    },
    async onUntilDateChange(update: any): Promise<void> {
      await this.validateAndResetUntilDate(update);
    },
    async validateAndResetFromDate(update: any): Promise<void> {
      if (isDateRangeInvalid(this.fromDate, this.untilDate)) {
        await this.addToastMessages({
          messages: [{ text: 'Das Datum von kann nicht nach dem Datum bis liegen.', class: 'error' }],
        });
        this.fromDate = update.oldValue;
      }
    },
    async validateAndResetUntilDate(update: any): Promise<void> {
      if (isDateRangeInvalid(this.fromDate, this.untilDate)) {
        await this.addToastMessages({
          messages: [{ text: 'Das Datum von kann nicht nach dem Datum bis liegen.', class: 'error' }],
        });

        this.untilDate = update.oldValue;
      }
    },
  },
});
